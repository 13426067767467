import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserProfile } from "../../../../../api/services";
import {
  getDealsDataById,
  getSignerStatus,
} from "../../../../../api/services/deals";
import SigneeCard from "./DealSignature";
import DealTabs from "../../DealTabs";
import Status from "../dealStatus/DealStatus";
import { Icons } from "../../../../../assets";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// Styled components
const StyledBox = styled(Box)({
  minHeight: "83vh",
});

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: "8px",
  padding: "20px",
  [theme.breakpoints.down("xl")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "5px",
  },
}));

const BreadcrumbBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: "#7643EB",
  cursor: "pointer",
  marginBottom: "0px",
}));

const ActionButtonBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
  },
  [theme.breakpoints.up("xl")]: {
    display: "flex",
  },
  flexDirection: "row",
  gap: "15px",
  padding: "10px",
}));

const DocumentBoxMobile = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "grid",
  },
  [theme.breakpoints.up("sm")]: {
    display: "grid",
  },
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
  [theme.breakpoints.up("lg")]: {
    display: "none",
  },
  [theme.breakpoints.up("xl")]: {
    display: "none",
  },
  padding: "10px",
  backgroundColor: "#efefef",
  margin: "2%",
  marginTop: "4%",
  borderRadius: "8px",
  gap: "10px",
}));

const PdfContainer = styled(Box)({
  backgroundColor: "#F9F9F9",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "30px",
});

const PdfViewer = styled(Card)(({ theme }) => ({
  padding: "15px",
  backgroundColor: "white",
  overflow: "auto",
  [theme.breakpoints.up("xs")]: {
    maxHeight: "30vh",
  },
  [theme.breakpoints.up("sm")]: {
    maxHeight: "55vh",
  },
  [theme.breakpoints.up("md")]: {
    maxHeight: "70vh",
  },
  [theme.breakpoints.up("lg")]: {
    maxHeight: "90vh",
  },
  [theme.breakpoints.up("xl")]: {
    maxHeight: "110vh",
  },
}));

function formatDate(dateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, options);
}

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
});

const RightPanel = styled(Box)({
  padding: 2,
});

export default function DealDocumentationSignee() {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [numPages, setNumPages] = useState(0);

  const { data: profileData } = useQuery("getUser-id", getUserProfile);
  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: (e: any) => {
        navigate("/deal-process/deals/all");
      },
    }
  );
  const data = dealData?.data;
  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.data?.id;

  const { mutate: status, isLoading: userLoader } = useMutation(
    getSignerStatus,
    {
      onSuccess: () => {
        toast.success("Status updated successfully");
        localStorage.removeItem("statusUpdated");
        queryClient.invalidateQueries("deal");
        window.location.reload();
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: statusForReload, isLoading: userLoaders } = useMutation(
    getSignerStatus,
    {
      onSuccess: () => {
        localStorage.removeItem("statusUpdated");
        queryClient.invalidateQueries("deal");
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  useEffect(() => {
    statusForReload({ data: { code: data?.code } });
  }, [statusForReload]);

  const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);

  const handleDownload = () => {
    fetch(data?.signedDocUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "deal.pdf";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  const handleStatus = () => {
    status({ data: { code: data?.code } });
  };

  if (userLoader || userLoaders || isLoading) {
    return <LinearProgress color="primary" />;
  }

  return (
    <StyledBox>
      <StyledCard>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              xl: "row",
              lg: "row",
            },
            justifyContent: "space-between",
            padding: {
              xs: "0px",
              sm: "0px",
              md: "0px 15px",
              xl: "0px 15px",
              lg: "0px 15px",
            },
          }}
        >
          <BreadcrumbBox>
            <IconButton
              size="medium"
              sx={{ mt: 0.5 }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "16px" }}
            >
              <Link
                underline="hover"
                color="#7643EB"
                href="/deal-process/deals/all"
                // variant="body1"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    xl: "14px",
                    lg: "14px",
                  },
                }}
              >
                Deals
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deal-process/deals/${dealId}`}
                // variant="body1"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    xl: "14px",
                    lg: "14px",
                  },
                }}
              >
                {dealId}
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deal-process/deals/${dealId}/documentationSignee`}
                // variant="body1"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    xl: "14px",
                    lg: "14px",
                  },
                }}
              >
                Deal Document
              </Link>
            </Breadcrumbs>
          </BreadcrumbBox>
          <ActionButtonBox>
            <Button
              variant="contained"
              color="primary"
              onClick={handleDownload}
              sx={{
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              Download
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleStatus}
              sx={{
                boxShadow: "0px 3px 6px #00000029",
              }}
            >
              Get Status
            </Button>
          </ActionButtonBox>
        </Box>
        <Box>
          <DealTabs data={data} accountOwner={firstPerson} />
        </Box>
        <Divider
          sx={{
            width: "100vw",
            ml: -8,
          }}
        />
        <DocumentBoxMobile>
          <Box
            sx={{
              px: "1vw",
              width: "100%",
              opacity: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              // justifyContent={"space-between"}
              gap={"10px"}
            >
              <Box>
                <img
                  height={"30px"}
                  src={Icons.pdf}
                  alt="loading....."
                  style={{ cursor: "pointer" }}
                />
              </Box>

              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontWeight: "500",
                  }}
                >
                  Deal Document
                </Typography>

                <Typography
                  variant="body2"
                  sx={{
                    marginTop: "5px",
                    fontWeight: "100",
                  }}
                >
                  Created on {formatDate(data.createdAt)}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                background: "#F2F2F2",
                borderRadius: "5px",
                opacity: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton
                onClick={() => {
                  window.open(data?.signedDocUrl, "_blank");
                }}
                sx={{
                  backgroundColor: "#d1d1d1",
                  borderRadius: "6px",
                  "&:hover": {
                    backgroundColor: "#c0c0c0", // Slightly darker shade for hover
                  },
                  "&:active": {
                    backgroundColor: "#d1d1d1", // Reverts back to original color on click
                  },
                }}
              >
                <RemoveRedEyeIcon sx={{ fontSize: "15px" }} />
              </IconButton>
            </Box>
          </Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleDownload}
            sx={{
              boxShadow: "0px 3px 6px #00000029",
              height: "30px",
              marginRight: "2%",
              marginLeft: "2%",
              marginBottom: "2%"
            }}
          >
            Download Document
          </Button>
        </DocumentBoxMobile>
        <Box display={"flex"} padding={"0px 0px"}>
          <Box
            sx={{
              width: "60%",
              padding: 2,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
            }}
          >
            <PdfContainer>
              <PdfViewer>
                <div
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    marginLeft: 10,
                    marginTop: -25,
                  }}
                >
                  <Document
                    file={data?.signedDocUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <div key={index}>
                        {index !== 0 && <Divider sx={{ my: 1.5 }} />}
                        <Page
                          pageNumber={index + 1}
                          className="pdf-page"
                          renderMode="canvas"
                          width={700}
                          loading="..."
                        />
                      </div>
                    ))}
                  </Document>
                </div>
              </PdfViewer>
            </PdfContainer>
          </Box>

          <RightPanel
            sx={{
              borderLeft: {
                xs: "none",
                sm: "none",
                md: "1px solid #1212121A",
                lg: "1px solid #1212121A",
                xl: "1px solid #1212121A",
              },
              width: {
                xs: "100%",
                sm: "100%",
                md: "32%",
                lg: "32%",
                xl: "32%",
              },
            }}
          >
            <SigneeCard
              data={data}
              accountOwner={firstPerson}
              needed={profileData}
            />
          </RightPanel>
        </Box>
      </StyledCard>

      <style>{`
        .pdf-page {
          margin-bottom: -16px; 
        }
      `}</style>
    </StyledBox>
  );
}
