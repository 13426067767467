import {
  Box,
  Button,
  Card,
  CardContent,
  Modal,
  Typography,
} from "@mui/material";
import React from "react";
import { dealReject } from "../../../../../assets";

interface DealDeclinedProps {
  open: boolean;
  onClose: () => void;
  data: any;
}

const DealDeclined: React.FC<DealDeclinedProps> = ({ open, onClose, data }) => {
  const rejectedComment = data?.statusHistory.find(
    (item: any) => item?.status === "Deal Rejected"
  )?.comment;

  const canceledComment = data?.statusHistory.find(
    (item: any) => item?.status === "Deal Cancelled"
  )?.comment;

  const htmlContent = rejectedComment || canceledComment;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card>
        <Box
          sx={{
            backgroundColor: "#FFFF",
            height: "auto",
            width: "50vw",
            borderRadius: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: "30px",
          }}
        >
          <Box sx={{ textAlign: "center", mb: "3%" }}>
            <img
              src={dealReject}
              alt="Card"
              style={{ width: "50%", objectFit: "cover" }}
            />
          </Box>

          <Typography variant="h4" id="modal-title">
            {canceledComment ? (
              <>Deal Cancelled</>
            ) : (
              <>Deal Invitation Declined!</>
            )}
          </Typography>

          <Typography
            variant="subtitle1"
            sx={{ mb: "3%", opacity: 0.6, textAlign: "center" }}
          >
            {canceledComment ? (
              <>
                The Trustee Authority has cancelled your deal, You can view the
                reason provided by the Trustee for cancelling the deal. This
                will allow you to understand their decision and determine if you
                would like to revise the deal and continue the process.
              </>
            ) : (
              <>
                We’re sorry, but the seller has declined your request to accept
                their deal invitation.
              </>
            )}
          </Typography>

          <Card
            sx={{
              width: "70%",
              height: "auto",
              background: "#E3DAFB",
              borderRadius: "15px",
              paddingBottom: "10px",
              mb: "3%",
            }}
          >
            <CardContent>
              <Typography variant="h6" sx={{ mb: "0px" }}>
                REASONS FOR REJECTION
              </Typography>
              <Typography
                variant="body2"
                sx={{ opacity: 1, fontSize: "15px" }}
                dangerouslySetInnerHTML={{ __html: htmlContent }}
              />
            </CardContent>
          </Card>

          <Button
            variant="contained"
            sx={{
              width: "30%",
              backgroundColor: "#6A1B9A",
              boxShadow: "0px 6px 16px #00000029",
              borderRadius: "8px",
              color: "#FFFFFF",
              mb: "3%",
            }}
            onClick={onClose}
          >
            Go Back
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};

export default DealDeclined;
