import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Card, Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import { escrowCard } from "../../../../../../assets";

type AccountCardProps = {
  bankDetails: any;
  dealData: any;
};

const AccountCard = ({ bankDetails, dealData }: AccountCardProps) => {
  const [showAccountBalance, setShowAccountBalance] = useState(false);

  const toggleAccountBalanceVisibility = () => {
    setShowAccountBalance(!showAccountBalance);
  };

  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }

  return (
    <>
      <Card
        sx={{
          backgroundImage: `url(${escrowCard})`,
          backgroundRepeat: "no-repeat",
          padding: "22px",
          backgroundSize: "100% 100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "20px",
          minWidth: {
            xs: "330px",
            sm: "330px",
            md: "330px",
            lg: "330px",
            xl: "350px",
          },
          maxWidth: {
            xs: "330px",
            sm: "350px",
            md: "350px",
            lg: "350px",
            xl: "450px",
          },
          height: {
            xs: "200px",
            sm: "240px",
            md: "240px",
            lg: "260px",
            xl: "290px",
          },
        }}
      >
        <Box>
          <Typography variant="body1" sx={{ opacity: 0.8, color: "#FFFFFF" }}>
            Account Number
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", opacity: 1, color: "#FFFFFF" }}
          >
            {bankDetails?.accountNumber}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              opacity: 0.8,
              marginTop: {
                xs: "10px",
                sm: "20px",
                md: "20px",
                lg: "25px",
                xl: "25px",
              },
              color: "#FFFFFF",
            }}
          >
            IFSC Code
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500", opacity: 1, color: "#FFFFFF" }}
          >
            {bankDetails?.ifscCode}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              opacity: 0.8,
              color: "#FFFFFF",
              marginTop: {
                xs: "10px",
                sm: "20px",
                md: "20px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            Account Balance
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500", opacity: 1, color: "#FFFFFF" }}
            >
              {showAccountBalance ? (
                <>
                  ₹{" "}
                  {formatNumberToIndianSystem(
                    dealData?.data?.overview?.balance
                  )}{" "}
                </>
              ) : (
                "**** ****"
              )}
            </Typography>
            <IconButton
              onClick={toggleAccountBalanceVisibility}
              sx={{ color: "#fff" }}
            >
              {showAccountBalance ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default AccountCard;
