import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function DealTabs({ data, accountOwner }: any) {
  const { dealId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (location.pathname.includes("documentationSignee")) {
      setValue(1);
    } else if (location.pathname.includes("truupe-account")) {
      setValue(2);
    } else if (location.pathname.includes("request-received")) {
      setValue(3);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    let targetRoute;

    switch (newValue) {
      case 1:
        targetRoute =
          data?.status === "Deal Accepted"
            ? `/deal-process/deals/${data?.code}/documentationSignee`
            : `/deal-process/deals/${data?.code}/documentationSignee`;
        break;
      case 2:
        targetRoute = `/deal-process/deals/${data?.code}/truupe-account`;
        break;
      case 3:
        // targetRoute = `/deal-process/deals/${dealId}/request-received`;
        break;
      default:
        targetRoute = `/deal-process/deals/${data?.code}`;
    }

    if (targetRoute) {
      navigate(targetRoute, { replace: true });
    }
  };

  return (
    <Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          allowScrollButtonsMobile={true}
          sx={{
            ".MuiTab-root": {
              "&:hover": {
                color: "#7643EB",
              },
              "&.Mui-selected": {
                color: "#7643EB",
              },
            },
            ".MuiTabs-indicator": {
              backgroundColor: "#7643EB",
            },
            ".MuiTabs-scrollButtons": {
              width: "10px",
            },
            marginLeft: {
              xs: "3%",
              sm: "3%",
              md: "0%",
              lg: "0%",
              xl: "0%",
            },
            marginRight: {
              xs: "3%",
              sm: "3%",
              md: "0%",
              lg: "0%",
              xl: "0%",
            },
          }}
        >
          <Tab label="Details" {...a11yProps(0)} sx={{ fontSize: "12px" }} />
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
              >
                Documentation
                {(data.status === "Invitation Issued" ||
                  data.status === "Deal Rejected") && (
                  <LockOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                )}
              </Box>
            }
            {...a11yProps(1)}
            disabled={
              data?.status === "Invitation Issued" ||
              data.status === "Deal Rejected"
            }
          />
          <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
              >
                Truupe Account
                {(data.state === "Deal Invitation" ||
                  data?.state === "Deal Documentation") && (
                  <LockOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                )}
              </Box>
            }
            {...a11yProps(2)}
            disabled={
              data?.state === "Deal Invitation" ||
              data?.state === "Deal Documentation"
            }
          />
          {/* <Tab
            label={
              <Box
                sx={{ display: "flex", alignItems: "center", fontSize: "12px" }}
              >
                View Payment Request
                {(data.state === "Deal Invitation" ||
                  data?.state === "Deal Documentation" ||
                  data?.status === "Funds Awaited") && (
                  <LockOutlinedIcon fontSize="small" sx={{ mr: 1 }} />
                )}
              </Box>
            }
            {...a11yProps(3)}
            disabled={
              data?.state === "Deal Invitation" ||
              data?.state === "Deal Documentation" ||
              data?.status === "Funds Awaited"
            }
          /> */}
        </Tabs>
      </Box>
    </Box>
  );
}
