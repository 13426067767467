import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import styled from "styled-components";
import { getEntityPreview } from "../../../../api/services";
import { Icons } from "../../../../assets";
import {
  formattedDate,
  maskAadharNumber,
  maskedPanNumber,
  truncateText,
} from "../../../../utils/previewUtils";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";
import BankingInformation from "../../../userProfile/banking-information/BankingInformation";

const SectionContainer = styled(Box)`
  padding: 20px;
`;

const SectionTitle = styled(Typography).attrs(() => ({
  variant: "h6",
  color: "black",
}))`
  padding-bottom: 16px;
`;

const InfoGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 4,
}))``;

const InfoItem = styled(Grid).attrs(() => ({
  item: true,
  xs: 3,
}))``;

const InfoText = styled(Typography).attrs(({ variant }) => ({
  variant: variant || "subtitle2",
}))``;

const DocumentBox = styled(Box)`
  width: 18vw;
  height: 10vh;
  border: 1px solid #1212121a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const DocumentInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DocumentTitle = styled(Typography).attrs(() => ({
  variant: "body1",
  gutterBottom: true,
}))`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentDate = styled(Typography).attrs(() => ({
  variant: "body1",
  gutterBottom: true,
}))`
  margin-top: 10px;
`;

interface PreviewComponentProps {
  kycType: string;
  data: any;
  EntityId: any;
}

export default function OtherEntityPreview({
  kycType,
  EntityId,
  data,
}: PreviewComponentProps) {
  const { data: previewData, isLoading }: any = useQuery(
    ["get-user-id", EntityId],
    getEntityPreview
  );
  const queryClient = useQueryClient();

  const renderSignatoryInfo = (director: any, index: number) => (
    <>
      <Box sx={{ mt: 2, mb: 1 }}>
        <Typography variant="subtitle2">
          Signatory Authority {index + 1}
        </Typography>
      </Box>
      <Grid container spacing={6}>
        {[
          { label: "Name", value: director?.name || "Not Available" },
          {
            label: "Designation",
            value: director?.designation || "Not Available",
          },
          {
            label: "Email Address",
            value: director?.emailAddress || "Not Available",
          },
          {
            label: "Mobile Number",
            value: director?.mobileNumber || "Not Available",
          },
          {
            label: "Aadhar Number",
            value: maskAadharNumber(director?.aadharNumber) || "Not Available",
          },
          {
            label: "Aadhar Status",
            value: director?.isAadharMismatch ? (
              <Typography color="red">Mismatch</Typography>
            ) : (
              <Typography color="green">Verified</Typography>
            ),
          },
          {
            label: "PAN Number",
            value: maskedPanNumber(director?.panNumber) || "Not Available",
          },
          // ...(kycType !== "partnership-firm"
          //   ? [
          //       {
          //         label: "PAN Status",
          //         value: director?.isPanMisMatch ? (
          //           <Typography color="red">Mismatch</Typography>
          //         ) : (
          //           <Typography color="green">Verified</Typography>
          //         ),
          //       },
          //     ]
          //   : []),
        ].map((info, idx) => (
          <Grid item xs={2} key={idx}>
            <Typography variant="caption">{info.label}</Typography>
            <Typography variant="subtitle2">{info.value}</Typography>
          </Grid>
        ))}
      </Grid>
    </>
  );

  const renderDocuments = (documents: any[]) =>
    documents.map((document, idx) => (
      <Grid item xs={4} key={idx}>
        <Typography
          variant="caption"
          sx={{
            marginBottom: "10px",
          }}
        >
          {document?.title}
        </Typography>
        <DocumentBox>
          <DocumentInfo>
            <img
              height="45px"
              src={Icons.pdf}
              alt="loading....."
              style={{ cursor: "pointer" }}
            />
            <Box>
              <DocumentTitle>
                {truncateText(
                  document?.originalFileName || document?.originalFileName,
                  15
                )}
              </DocumentTitle>
              <DocumentDate>Added on {formattedDate}</DocumentDate>
            </Box>
          </DocumentInfo>
        </DocumentBox>
      </Grid>
    ));

  if (isLoading) {
    return (
      <Box
        display={"flex"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={"4rem"}></CircularProgress>;
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <SectionContainer>
        <SectionTitle>
          {kycType === "individual"
            ? "Individual Information"
            : "Entity Information"}
        </SectionTitle>
        {kycType === "individual" || kycType === "sole-proprietorship" ? (
          <>
            <InfoGrid>
              <InfoItem>
                <InfoText variant="caption">Name</InfoText>
                <InfoText>
                  {previewData?.data?.directors[0]?.name || "Not Available"}
                </InfoText>
              </InfoItem>
              <InfoItem>
                <InfoText variant="caption">Email Address</InfoText>
                <InfoText>
                  {data?.data?.emailAddress || "Not Available"}
                </InfoText>
              </InfoItem>
              <InfoItem>
                <InfoText variant="caption">Contact Address</InfoText>
                <InfoText>
                  {previewData?.data?.registeredAddress || "Not Available"}
                </InfoText>
              </InfoItem>
            </InfoGrid>
            <InfoGrid direction="column">
              <InfoItem>
                <InfoText variant="caption">Aadhar Number</InfoText>
                <InfoText>
                  {maskAadharNumber(
                    previewData?.data?.directors[0]?.aadharNumber
                  ) || "Not Available"}
                </InfoText>
              </InfoItem>
              <InfoItem>
                <InfoText variant="caption">Date of Birth</InfoText>
                <InfoText>
                  {previewData?.data?.directors[0]?.dateOfBirth ||
                    "Not Available"}
                </InfoText>
              </InfoItem>
            </InfoGrid>
          </>
        ) : (
          <InfoGrid>
            {[
              { label: "Company Name", value: previewData?.data?.companyName },
            ].map((info, idx) => (
              <InfoItem key={idx}>
                <InfoText variant="caption">{info.label}</InfoText>
                <InfoText>{info.value}</InfoText>
              </InfoItem>
            ))}
          </InfoGrid>
        )}
      </SectionContainer>
      <Divider />
      {kycType !== "individual" && kycType !== "sole-proprietorship" && (
        <SectionContainer>
          <SectionTitle>Signatory Authority Information</SectionTitle>
          <Box>
            <Typography variant="caption">
              Number of Signatory Authorities
            </Typography>
            <Typography variant="subtitle2">
              {previewData?.data?.directors?.length}
            </Typography>
          </Box>
          {previewData?.data?.directors?.map(renderSignatoryInfo)}
        </SectionContainer>
      )}
      <Divider sx={{ marginBottom: "20px" }} />
      <Box sx={{ width: "98%" }}>
        <BankingInformation data={previewData} isEditable={false} />
      </Box>
      <Divider />
      {kycType !== "individual" && (
        <SectionContainer>
          <SectionTitle>Entity Documents</SectionTitle>
          <Grid container spacing={3}>
            {previewData?.data?.firmDocuments?.map((doc: any, idx: number) => {
              return (
                <Grid item xs={4} key={idx}>
                  <FileUploadContainer
                    typeId={previewData?.data?.id}
                    actions={<></>}
                    data={doc}
                    typeData="ENTITY"
                    name={doc.originalFileName}
                    url={doc?.url}
                    subType={doc.subtype}
                    showIcons={false}
                    uploaded={true}
                    onFileChange={() => {
                      queryClient.invalidateQueries("get-user-id");
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </SectionContainer>
      )}
      <Divider />
      <SectionContainer>
        <SectionTitle>
          {kycType === "individual"
            ? "Individual Documents"
            : "Signatory Authority Documents"}
        </SectionTitle>
        {kycType !== "individual" && (
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography variant="caption">
              Number of Signatory Authorities :
            </Typography>
            <Typography> {previewData?.data?.directors?.length}</Typography>
          </Box>
        )}
        {previewData?.data?.directors?.map((director: any, index: number) => (
          <Box key={index}>
            <Box sx={{ mt: 2, mb: 1 }}>
              <Typography variant="h5">{director?.name}</Typography>
            </Box>
            <InfoGrid>
              {director?.documents?.map((doc: any, idx: number) => {
                return (
                  <Grid item xs={4} key={idx}>
                    <FileUploadContainer
                      typeId={director?.id}
                      actions={<></>}
                      data={doc}
                      typeData="DIRECTOR"
                      name={doc?.originalFileName}
                      url={doc?.url}
                      subType={doc?.subtype}
                      showIcons={false}
                      uploaded={true}
                      onFileChange={() => {
                        queryClient.invalidateQueries("get-user-id");
                      }}
                    />
                  </Grid>
                );
              })}
            </InfoGrid>
          </Box>
        ))}
      </SectionContainer>
    </Box>
  );
}
