import React from "react";
import { Divider, Grid, Stack, Typography, styled } from "@mui/material";

interface DirectorDocumentInformationProps {
  mainData: any;
  isSupporting: boolean;
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginBottom: "20px",
  marginLeft: "20px",
  marginRight: "20px",
}));

const DocumentInfoGrid = styled(Grid)(({ theme }) => ({
  marginBottom: "20px",
}));

const DocumentInfoText = styled(Typography)(({ theme }) => ({
  color: "#000000",
  opacity: 0.7,
  marginLeft: "20px",
  marginRight: "20px",
}));

const SignatoryDocumentInformation: React.FC<
  DirectorDocumentInformationProps
> = ({ mainData, isSupporting }) => {
  const company_type = mainData?.data?.companyType;

  switch (company_type) {
    case "PRIVATE_LIMITED":
    case "LIMITED_LIABILITY_PARTNERSHIP":
    case "PARTNERSHIP":
    case "OTHER":
      return (
        <>
          <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />
          <SectionTitle variant="h4">
            <>
              {isSupporting
                ? `Supporting Signature Authority Documents`
                : `Signature Authority Documents`}
            </>
          </SectionTitle>
        </>
      );
    case "SOLE_PROPRIETORSHIP":
      return (
        <>
          <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />

          <SectionTitle variant="h6">
            {isSupporting
              ? `Supporting Signature Authority Documents`
              : `Signature Authority Documents`}
          </SectionTitle>
        </>
      );
    case "INDIVIDUAL":
      return (
        <>
          {isSupporting ? (
            <>
              {" "}
              <Divider
                sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }}
              />
            </>
          ) : (
            <></>
          )}

          <SectionTitle variant="h6">
            {isSupporting
              ? `Supporting Individual Documents`
              : `Individual Documents`}
          </SectionTitle>
        </>
      );
    default:
      return null;
  }
};

export default SignatoryDocumentInformation;
