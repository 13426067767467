import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../../api/services";
import { getDealsDataById } from "../../../../api/services/deals";
import DealDetails from "./dealInformation/DealDetails";
import DealInfo from "./dealInformation/DealInfo";
import DealStates from "./dealStates/DealStates";

import DealTabs from "../DealTabs";
import Status from "./dealStatus/DealStatus";

// Styled components
const ContainerBox = styled(Box)({
  padding: "0px 20px",
  minHeight: "83vh",
});

const StyledCard = styled(Card)({
  borderRadius: "8px",
  padding: "20px",
});

const BreadcrumbBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  color: "#7643EB",
  cursor: "pointer",
});

const HeaderBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const InfoSection = styled(Box)({
  width: "25vw",
  borderRight: "1px solid #1212121A",
  padding: "0 16px",
});

const DetailsSection = styled(Box)({
  width: "75vw",
  padding: "0 16px",
});

export default function UserDeal() {
  const { dealId }: any = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const { data: profileData } = useQuery("getUser-id", getUserProfile);

  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deal-process/deals/all");
      },
    }
  );

  const data = dealData?.data;
  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.data?.id;

  const profileUserRole = dealData?.data?.dealUsers?.find((user: any) => {
    return user?.userId === profileData?.data?.id;
  })?.userType;

  localStorage.setItem("presentStatus", dealData?.data?.status);
  localStorage.setItem("presentState", dealData?.data?.state);
  localStorage.setItem("dealcode", dealId);
  localStorage.setItem("UserRole", profileUserRole);

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      {isMobile || isTablet ? (
        <>
          <BreadcrumbBox>
            <IconButton
              size="medium"
              sx={{ mt: 0.5 }}
              onClick={() => navigate(-1)}
            >
              <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#7643EB" }}>
              <Link
                underline="hover"
                color="#7643EB"
                href="/deal-process/deals/all"
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    xl: "14px",
                    lg: "14px",
                  },
                }}
              >
                Deals
              </Link>
              <Link
                underline="hover"
                color="#7643EB"
                href={`/deal-process/deals/${dealId}`}
                sx={{
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "14px",
                    xl: "14px",
                    lg: "14px",
                  },
                }}
              >
                {dealId}
              </Link>
            </Breadcrumbs>
          </BreadcrumbBox>

          <HeaderBox
            ml="3%"
            mr="3%"
            sx={{
              marginBottom: {
                xs: "0px",
                sm: "0px",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
            }}
          >
            <Typography variant="h5" my="10px">
              {dealId}
            </Typography>
            <Status variant={data?.status} />
          </HeaderBox>

          <Box>
            <DealTabs data={data} accountOwner={firstPerson} />
          </Box>
          <Divider sx={{ width: "100%", opacity: "0.5" }} />

          <Box display="flex" flexDirection={"column"}>
            <DealDetails
              data={data}
              accountOwner={firstPerson}
              profileData={profileData}
            />
            <DealInfo data={data} accountOwner={firstPerson} />
          </Box>
        </>
      ) : (
        <>
          {" "}
          <ContainerBox>
            <StyledCard>
              <BreadcrumbBox>
                <IconButton
                  size="medium"
                  sx={{ mt: 0.5 }}
                  onClick={() => navigate(-1)}
                >
                  <ChevronLeftIcon
                    fontSize="medium"
                    sx={{ color: "#7643EB" }}
                  />
                </IconButton>
                <Breadcrumbs aria-label="breadcrumb" sx={{ color: "#7643EB" }}>
                  <Link
                    underline="hover"
                    color="#7643EB"
                    href="/deal-process/deals/all"
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        xl: "14px",
                        lg: "14px",
                      },
                    }}
                  >
                    Deals
                  </Link>
                  <Link
                    underline="hover"
                    color="#7643EB"
                    href={`/deal-process/deals/${dealId}`}
                    sx={{
                      fontSize: {
                        xs: "12px",
                        sm: "14px",
                        md: "14px",
                        xl: "14px",
                        lg: "14px",
                      },
                    }}
                  >
                    {dealId}
                  </Link>
                </Breadcrumbs>
              </BreadcrumbBox>

              <HeaderBox>
                <Typography variant="h5" my="10px" ml={2}>
                  {dealId}
                </Typography>
                <Status variant={data?.status} />
              </HeaderBox>

              <Box sx={{ ml: 2 }}>
                <DealTabs data={data} accountOwner={firstPerson} />
              </Box>
              <Divider sx={{ width: "100%", opacity: "0.5" }} />
              <Box display="flex">
                <InfoSection>
                  <DealInfo data={data} accountOwner={firstPerson} />
                </InfoSection>
                <DetailsSection>
                  <DealDetails
                    data={data}
                    accountOwner={firstPerson}
                    profileData={profileData}
                  />
                </DetailsSection>
              </Box>
            </StyledCard>
          </ContainerBox>
          <DealStates data={data} />
        </>
      )}
    </>
  );
}
