import { Navigate, useLocation, useParams } from "react-router-dom";

type RestectProps = {
  children: any;
};

export const RestrictEntityType = ({ children }: RestectProps) => {
  const entityStatus: any = localStorage.getItem("entityStatus");
  if (
    entityStatus === "Kyc Submitted" ||
    entityStatus === "Verified" ||
    entityStatus === "Rejected"
  ) {
    return <Navigate to="/home" replace />;
  }
  return children;
};

export const RestrictDealDocument = ({ children }: RestectProps) => {
  const { dealId } = useParams();

  const presentStatus = localStorage.getItem("presentStatus");
  const presentState = localStorage.getItem("presentState");
  const dealcode = localStorage.getItem("dealcode");
  if (
    presentState !== "Deal Invitation" &&
    dealId === dealcode &&
    presentStatus !== "Invitation Issued" &&
    (presentStatus !== null || presentState !== null)
  ) {
    return children;
  }
  return <Navigate to="/deal-process/deals/all" replace />;
};

export const RestrictPlatformFee = ({ children }: RestectProps) => {
  const { dealId } = useParams();
  const presentStatus = localStorage.getItem("presentStatus");
  const presentState = localStorage.getItem("presentState");
  const dealcode = localStorage.getItem("dealcode");
  const userRole = localStorage.getItem("UserRole");
  if (
    presentState === "Deal Documentation" &&
    dealId === dealcode &&
    userRole === "Buyer" &&
    presentStatus === "Fee Pending" &&
    (presentStatus !== null ||
      presentState !== null ||
      userRole !== null ||
      dealcode !== null)
  ) {
    return children;
  }
  return <Navigate to="/deal-process/deals/all" replace />;
};

export const RestrictTruupeAccount = ({ children }: RestectProps) => {
  const { dealId } = useParams();
  const presentStatus = localStorage.getItem("presentStatus");
  const presentState = localStorage.getItem("presentState");
  const dealcode = localStorage.getItem("dealcode");
  const userRole = localStorage.getItem("UserRole");
  if (
    (presentState === "Deal InProgress" ||
      presentState === "Deal Completion" ||
      presentState === "Escrow Account") &&
    dealId === dealcode &&
    (presentStatus !== null ||
      presentState !== null ||
      userRole !== null ||
      dealcode !== null)
  ) {
    return children;
  }
  return <Navigate to="/deal-process/deals/all" replace />;
};

export const RestrictInitiatePayment = ({ children }: RestectProps) => {
  const { dealId } = useParams();
  const presentStatus = localStorage.getItem("presentStatus");
  const presentState = localStorage.getItem("presentState");
  const dealcode = localStorage.getItem("dealcode");
  const userRole = localStorage.getItem("UserRole");
  if (
    (presentState === "Deal InProgress" ||
      presentState === "Deal Completion" ||
      presentState === "Escrow Account") &&
    userRole === "Buyer" &&
    dealId === dealcode &&
    (presentStatus !== null ||
      presentState !== null ||
      userRole !== null ||
      dealcode !== null)
  ) {
    return children;
  }
  return <Navigate to="/deal-process/deals/all" replace />;
};

export const RestrictRequestPayment = ({ children }: RestectProps) => {
  const { dealId } = useParams();
  const presentStatus = localStorage.getItem("presentStatus");
  const presentState = localStorage.getItem("presentState");
  const dealcode = localStorage.getItem("dealcode");
  const userRole = localStorage.getItem("UserRole");
  if (
    (presentState === "Deal InProgress" ||
      presentState === "Deal Completion" ||
      presentState === "Escrow Account") &&
    userRole === "Seller" &&
    dealId === dealcode &&
    (presentStatus !== null ||
      presentState !== null ||
      userRole !== null ||
      dealcode !== null)
  ) {
    return children;
  }
  return <Navigate to="/deal-process/deals/all" replace />;
};

export const RestrictRequestReceived = ({ children }: RestectProps) => {
  const { dealId } = useParams();
  const presentStatus = localStorage.getItem("presentStatus");
  const presentState = localStorage.getItem("presentState");
  const dealcode = localStorage.getItem("dealcode");
  const userRole = localStorage.getItem("UserRole");
  if (
    (presentState === "Deal InProgress" ||
      presentState === "Deal Completion" ||
      presentState === "Escrow Account") &&
    dealId === dealcode &&
    (presentStatus !== null ||
      presentState !== null ||
      userRole !== null ||
      dealcode !== null)
  ) {
    return children;
  }
  return <Navigate to="/deal-process/deals/all" replace />;
};
