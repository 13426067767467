import {
  Box,
  Button,
  Card,
  Divider,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { dealAmount } from "../../../../../assets";

type DealAmountCardProps = {
  data: any;
  profileData: any;
};
const DealAmountCard = ({ data, profileData }: DealAmountCardProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const isUserBuyer = data?.dealUsers?.some(
    (user: any) =>
      user?.userId === profileData?.data?.id && user?.userType === "Buyer"
  );

  const { overview } = data;
  const { amount, status } = data;
  const escrowBalancePercentage =
    (Number(overview?.balance) / Number(amount)) * 100;
  const debitedPercentage = (Number(overview?.debit) / Number(amount)) * 100;

  const formatNumberToIndianSystem = (number: number) =>
    new Intl.NumberFormat("en-IN").format(number);

  const renderCardContent = (icon: string, amount: number, label: string) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "30px",
        justifyContent: "start",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        borderRadius="20%"
        bgcolor="rgba(106, 27, 154, 0.1)"
        sx={{
          height: {
            xs: "40px",
            sm: "40px",
            md: "60px",
            lg: "60px",
            xl: "60px",
          },
          width: {
            xs: "40px",
            sm: "40px",
            md: "60px",
            lg: "60px",
            xl: "60px",
          },
        }}
      >
        <img
          src={icon}
          style={{ height: "75%", width: "75%" }}
          alt="loading..."
        />
      </Box>
      <Box display="flex" flexDirection="column">
        <Typography
          sx={{
            fontWeight: 500,
            typography: {
              xs: "subtitle1",
              sm: "subtitle1",
              md: "h6",
              lg: "h6",
              xl: "h6",
            },
          }}
        >
          ₹ {formatNumberToIndianSystem(amount)}
        </Typography>
        <Typography
          sx={{
            opacity: 0.6,
            typography: {
              xs: "body2",
              sm: "body2",
              md: "subtitle2",
              lg: "subtitle2",
              xl: "subtitle2",
            },
          }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box
      padding={"10px 5px"}
      gap={isMobile ? "20px" : "10px"}
      sx={{
        marginLeft: { sm: "0px", xs: "0px", md: "0px", xl: "20px", lg: "20px" },
        display: "flex",
        flexDirection: {
          sm: "column",
          xs: "column",
          md: "column",
          xl: "row",
          lg: "row",
        },
        alignItems: { xs: "none", sm: "flex-start", md: "flex-start" },
        justifyContent: {
          sm: "center",
          xs: "center",
          md: "flex-start",
          xl: "flex-start",
          lg: "flex-start",
        },
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#F3EFFF",
          borderRadius: "10px",
          width: { xs: "auto", sm: "60vw", md: "40vw", lg: "21vw", xl: "21vw" },
          mx: { xs: "20px", sm: "20px", md: "0px", lg: "0px", xl: "0px" },
        }}
      >
        <Box
          sx={{
            padding: {
              xs: "4% 4% 3% 4%",
              sm: "4% 4% 3% 4%",
              md: "4% 3% 3% 3%",
              lg: [
                "Invitation Issued",
                "Deal Accepted",
                "Deal Rejected",
                "Signature Pending",
                "Verification Pending",
                "Fee Pending",
              ].includes(status)
                ? "1% 1% 1% 1%"
                : "4% 3% 3% 3%",
              xl: [
                "Invitation Issued",
                "Deal Accepted",
                "Deal Rejected",
                "Signature Pending",
                "Verification Pending",
                "Fee Pending",
              ].includes(status)
                ? "1% 1% 1% 1%"
                : "4% 3% 3% 3%",
            },
          }}
        >
          {renderCardContent(dealAmount, amount, "Deal Amount")}
          {![
            "Invitation Issued",
            "Deal Accepted",
            "Deal Rejected",
            "Signature Pending",
            "Verification Pending",
          ].includes(status) && (
            <>
              <Divider sx={{ my: "10px" }} />
              <Box sx={{ width: "auto" }}>
                <Typography
                  sx={{
                    opacity: 0.6,
                    textAlign: "right",
                    marginRight: "1vw",
                    typography: {
                      xs: "subtitle2",
                      sm: "subtitle2",
                      md: "subtitle1",
                      lg: "subtitle1",
                      xl: "subtitle1",
                    },
                  }}
                >
                  This is Total Deal Amount
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Card>

      {[
        "Funds Awaited",
        "Funds Received",
        "Payment Verification",
        "Deal Completed",
      ].includes(status) && (
        <Card
          sx={{
            alignItems: "center",
            borderRadius: 3,
            backgroundColor: "#F3EFFF",
            border: "1px solid #1212121A",
            width: {
              xs: "auto",
              sm: "60vw",
              md: "40vw",
              lg: "21vw",
              xl: "21vw",
            },
            mx: {
              xs: "20px",
              sm: "20px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Box
            sx={{
              padding: {
                xs: "4% 4% 3% 4%",
                sm: "4% 4% 3% 4%",
                md: "4% 3% 3% 3%",
                lg: "4% 3% 3% 3%",
                xl: "4% 3% 3% 3%",
              },
            }}
          >
            {renderCardContent(
              dealAmount,
              overview?.balance,
              "Account Balance"
            )}
            <Divider sx={{ my: "10px", marginRight: "1vw" }} />
            <Typography
              sx={{
                opacity: 0.6,
                textAlign: "right",
                marginRight: "1vw",
                typography: {
                  xs: "subtitle2",
                  sm: "subtitle2",
                  md: "subtitle1",
                  lg: "subtitle1",
                  xl: "subtitle1",
                },
              }}
            >
              ₹ {formatNumberToIndianSystem(overview?.balance)} out of ₹{" "}
              {formatNumberToIndianSystem(amount)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={escrowBalancePercentage || 0}
              sx={{
                mt: "10px",
                mb: "5px",
              }}
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            onClick={() => queryClient.invalidateQueries("deal")}
            sx={{
              backgroundColor: "#8B5CF6",
              width: "100%",
              borderRadius: "0 0 12px 12px",
              "&:hover": { backgroundColor: "#7C3AED" },
              padding: {
                xs: "20px",
                sm: "20px",
                md: "25px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            Get balance
          </Button>
        </Card>
      )}

      {["Funds Received", "Payment Verification", "Deal Completed"].includes(
        status
      ) && (
        <Card
          sx={{
            alignItems: "center",
            borderRadius: 3,
            backgroundColor: "#F3EFFF",
            border: "1px solid #1212121A",
            width: {
              xs: "auto",
              sm: "60vw",
              md: "40vw",
              lg: "21vw",
              xl: "21vw",
            },
            mx: {
              xs: "20px",
              sm: "20px",
              md: "0px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Box
            sx={{
              padding: {
                xs: "4% 4% 3% 4%",
                sm: "4% 4% 3% 4%",
                md: "4% 3% 3% 3%",
                lg: "4% 3% 3% 3%",
                xl: "4% 3% 3% 3%",
              },
            }}
          >
            {renderCardContent(
              dealAmount,
              overview?.debit,
              isUserBuyer ? "Payment Sent" : "Payment Received"
            )}
            <Divider sx={{ my: "10px", marginRight: "1vw" }} />
            <Typography
              sx={{
                opacity: 0.6,
                textAlign: "right",
                marginRight: "1vw",
                typography: {
                  xs: "subtitle2",
                  sm: "subtitle2",
                  md: "subtitle1",
                  lg: "subtitle1",
                  xl: "subtitle1",
                },
              }}
            >
              ₹ {formatNumberToIndianSystem(overview?.debit)} out of ₹{" "}
              {formatNumberToIndianSystem(amount)}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={debitedPercentage || 0}
              sx={{
                mt: "10px",
                mb: "5px",
              }}
            />
          </Box>

          {isUserBuyer && (
            <Button
              variant="contained"
              color="primary"
              disabled={data?.status === "Deal Completed" || !isUserBuyer}
              onClick={() =>
                navigate(
                  `/deal-process/deals/${data?.code}/${
                    isUserBuyer ? "initiate-payment" : "request-payment"
                  }`
                )
              }
              sx={{
                backgroundColor: "#8B5CF6",
                ml: -3,
                // mt: { xs: 0, sm: 0, md: 2, lg: 2, xl: 2 },
                width: "110%",
                borderRadius: "0 0 12px 12px",
                "&:hover": { backgroundColor: "#7C3AED" },
                padding: {
                  xs: "20px",
                  sm: "20px",
                  md: "25px",
                  lg: "25px",
                  xl: "25px",
                },
              }}
            >
              {/* {isUserBuyer ? "Initiate Payment" : "Request Payment"} */}
              {isUserBuyer ? "Initiate Payment" : ""}
            </Button>
          )}
        </Card>
      )}
    </Box>
  );
};

export default DealAmountCard;
