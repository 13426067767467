import NotificationsActiveOutlinedIcon from "@mui/icons-material/NotificationsActiveOutlined";
import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postNotify } from "../../../api/services/deals";
import DealDeclined from "../../dealProcess/deals/userDeal/modalCards/DealDeclined";
import Status from "../deals/userDeal/dealStatus/DealStatus";
import RejectionModal from "../deals/userDeal/modalCards/Reasonrejection";
import ApproveModel from "../deals/userDeal/modalCards/DealApproveModel";

const ActiveDeals = ({ profiledata, dealData }: any) => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleOpen2 = () => setOpen2(true);

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const data = dealData?.data?.data;

  const navigate = useNavigate();

  const [isModalRejectOpen, setIsModalRejectOpen] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState(null);

  const handleRejectOpen = (deal: any) => {
    setSelectedDeal(deal);
    setIsModalRejectOpen(true);
  };

  const handleRejectClose = () => {
    setIsModalRejectOpen(false);
  };

  function formatNumberToIndianSystem(number: number) {
    return new Intl.NumberFormat("en-IN").format(number);
  }

  const { mutate: notify } = useMutation(postNotify, {
    onSuccess: (res: any) => {
      toast.success("Notification sent successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleNotifySeller = (dealCode: string) => {
    notify({ dealCode });
  };

  return (
    <Box>
      <Box sx={{ margin: "0px 40px" }}>
        <Typography variant="h5" sx={{ mb: "1vw" }}>
          Hello, {profiledata?.data?.name}!
        </Typography>
        <Typography variant="h6">Active Deals</Typography>

        {data?.map((deal: any, index: number) => {
          const datas = deal;

          const profileUser = deal?.dealUsers?.find(
            (user: any) => user?.userId === profiledata?.data?.id
          );
          const secondUser = deal?.dealUsers?.find(
            (user: any) => user?.userId !== deal.createdBy?.id
          );

          const owner = deal?.dealUsers?.find(
            (user: any) => user?.userId === deal.createdBy?.id
          );

          const dealCreator = deal?.createdBy?.id === profiledata?.data?.id;

          const duration = deal?.duration > 1 ? "Months" : "Month";

          const isuserDocumnetSigned =
            deal?.dealSignatures?.find(
              (signature: any) => signature?.userId === profiledata?.data?.id
            )?.status === "Completed";
          return (
            <Card sx={{ px: "2vw", py: "4vh", my: "2vh" }}>
              <Box
                key={index}
                sx={{
                  borderRadius: "1vw",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="h2" sx={{ color: "#7643EB" }}>
                    {deal?.code}
                  </Typography>

                  {}
                  <Box>
                    {datas?.status === "Invitation Issued" ? (
                      <>
                        {dealCreator ? (
                          <>
                            <Status variant={"Confirmation Awaiting"} />
                          </>
                        ) : (
                          <>
                            <Status variant={"Invitation Issued"} />
                          </>
                        )}
                      </>
                    ) : datas?.status === "Signature Pending" ? (
                      isuserDocumnetSigned ? (
                        <Status variant={"Signature Awaiting"} />
                      ) : (
                        <Status variant={"Signature Pending"} />
                      )
                    ) : datas?.status === "Fee Pending" ? (
                      profileUser?.userType === "Buyer" ? (
                        <Status variant={"Service Payment"} />
                      ) : (
                        <Status variant={"Funds Awaited"} />
                      )
                    ) : datas?.status === "Funds Awaited" ? (
                      profileUser?.userType === "Buyer" ? (
                        <Status variant={"Awaiting Escrow Funds"} />
                      ) : (
                        <Status variant={"Funds Awaited"} />
                      )
                    ) : (
                      <Status variant={datas?.status} />
                    )}
                  </Box>
                </Box>

                <Divider sx={{ my: "1vh" }} />

                <Grid container spacing={6} sx={{ marginBottom: "2vw" }}>
                  <Grid item xs={4} md={3}>
                    <Typography variant="caption">Deal Title</Typography>
                    <Typography variant="subtitle2">{deal?.title}</Typography>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Deal Amount
                    </Typography>
                    <Typography variant="subtitle2">
                      ₹ {formatNumberToIndianSystem(deal?.amount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Deal Duration
                    </Typography>
                    <Typography variant="subtitle2">
                      {deal?.duration} {duration}
                    </Typography>
                  </Grid>
                </Grid>

                <Typography variant="h6" sx={{ my: "2vh" }}>
                  Deal {secondUser?.userType} Information
                </Typography>

                <Grid container spacing={6}>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      {secondUser?.userType} Name
                    </Typography>
                    <Typography variant="subtitle2">
                      {secondUser?.user?.name || "Not Registered"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Entity Name
                    </Typography>
                    <Typography variant="subtitle2">
                      {secondUser?.entity?.companyType === "INDIVIDUAL" ? (
                        <> {secondUser?.user?.name}</>
                      ) : (
                        <>
                          {" "}
                          {secondUser?.entity?.companyName || "Not Registered"}
                        </>
                      )}
                      {}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Entity Category
                    </Typography>
                    <Typography variant="subtitle2">
                      {secondUser?.entity?.companyType === "INDIVIDUAL" ? (
                        <> {"INDIVIDUAL"}</>
                      ) : (
                        <> {secondUser?.entity?.category || "Not Registered"}</>
                      )}
                      {}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Email Address
                    </Typography>
                    <Typography variant="subtitle2">
                      {secondUser?.user?.emailAddress || "Not Registered"}
                    </Typography>
                  </Grid>
                </Grid>

                <Divider sx={{ my: "1vh" }} />

                <Typography variant="h6" sx={{ my: "2vh" }}>
                  Deal {owner?.userType} Information
                </Typography>

                <Grid container spacing={6}>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      {owner?.userType} Name
                    </Typography>
                    <Typography variant="subtitle2">
                      {owner?.user?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Entity Name
                    </Typography>
                    <Typography variant="subtitle2">
                      {owner?.entity?.companyType === "INDIVIDUAL" ? (
                        <> {owner?.user?.name}</>
                      ) : (
                        <> {owner?.entity?.companyName || "Not Registered"}</>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Entity Category
                    </Typography>
                    <Typography variant="subtitle2">
                      {owner?.entity?.companyType === "INDIVIDUAL" ? (
                        <> {"INDIVIDUAL"}</>
                      ) : (
                        <>{owner?.entity?.category || "Not Registered"}</>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={3}>
                    <Typography variant="caption" sx={{ opacity: 0.6 }}>
                      Email Address
                    </Typography>
                    <Typography variant="subtitle2">
                      {owner?.user?.emailAddress || "Not Registered"}
                    </Typography>
                  </Grid>
                </Grid>

                {deal?.status === "Fee Pending" &&
                  profileUser?.userType === "Buyer" && (
                    <Box sx={{ mt: "2vh" }}>
                      <Button
                        variant="contained"
                        onClick={() =>
                          navigate(
                            `/deal-process/deals/${deal?.code}/platform-fee`,
                            {
                              replace: true,
                            }
                          )
                        }
                      >
                        Pay Handling Fee
                      </Button>
                    </Box>
                  )}

                {deal?.status === "Funds Awaited" &&
                  profileUser?.userType === "Buyer" && (
                    <Box sx={{ mt: "2vh" }}>
                      <Button variant="contained">How to Add Funds</Button>
                    </Box>
                  )}

                {deal?.status === "Verification Pending" && (
                  <Box sx={{ mt: "2vh" }}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/deal-process/deals/${deal?.code}/documentationSignee`,
                          {
                            replace: true,
                          }
                        )
                      }
                    >
                      View Documentation
                    </Button>
                  </Box>
                )}

                {deal?.status === "Signature Pending" && (
                  <Box sx={{ mt: "2vh" }}>
                    {isuserDocumnetSigned ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => handleNotifySeller(deal?.code)}
                        >
                          <NotificationsActiveOutlinedIcon
                            sx={{ marginRight: 1, borderRadius: 1 }}
                          />{" "}
                          Notify signee
                          {}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          onClick={() =>
                            navigate(
                              `/deal-process/deals/${deal?.code}/documentationSignee`,
                              {
                                replace: true,
                              }
                            )
                          }
                        >
                          Sign Document
                        </Button>
                      </>
                    )}
                  </Box>
                )}
                {deal?.status === "Deal Accepted" && (
                  <Box sx={{ mt: "2vh" }}>
                    <Button
                      variant="contained"
                      onClick={() =>
                        navigate(
                          `/deal-process/deals/${deal?.code}/documentationSignee`,
                          {
                            replace: true,
                          }
                        )
                      }
                    >
                      Sign Document
                    </Button>
                  </Box>
                )}

                {deal?.status === "Deal Rejected" && (
                  <Box sx={{ mt: "2vh" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleRejectOpen(deal)}
                    >
                      View Reason
                    </Button>
                  </Box>
                )}

                {deal?.state === "Deal Documentation" &&
                  deal?.status === " Deal Cancelled " && (
                    <Box sx={{ mt: "2vh" }}>
                      <Button
                        variant="contained"
                        onClick={() => handleRejectOpen(deal)}
                      >
                        View Reason
                      </Button>
                    </Box>
                  )}

                <DealDeclined
                  open={isModalRejectOpen}
                  onClose={handleRejectClose}
                  data={selectedDeal}
                />

                {deal?.status === "Confirmation Awaiting" && (
                  <Box sx={{ mt: "2vh" }}>
                    <Button
                      variant="contained"
                      onClick={() => handleNotifySeller(deal?.code)}
                    >
                      <NotificationsActiveOutlinedIcon
                        sx={{ marginRight: 1, borderRadius: 1 }}
                      />{" "}
                      Notify{secondUser?.userType}
                    </Button>
                  </Box>
                )}

                <RejectionModal
                  open={open}
                  onClose={handleClose}
                  data={deal}
                  secondUser={secondUser}
                  deal={deal}
                />
                <ApproveModel
                  open={open2}
                  onClose={handleClose2}
                  data={deal}
                  secondUser={secondUser}
                  deal={deal}
                />
                {deal?.status === "Invitation Issued" && (
                  <Box sx={{ mt: "2vh" }}>
                    {dealCreator ? (
                      <>
                        <Button
                          variant="contained"
                          onClick={() => {
                            notify({ dealCode: deal?.code });
                          }}
                        >
                          Notify {secondUser?.userType}
                        </Button>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            textAlign: "left",
                            gap: "20px",
                            mt: 2,
                          }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleOpen}
                            sx={{
                              width: 150,
                              height: 40,
                              border: "2px solid #7643EB",
                            }}
                          >
                            Reject Deal
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleOpen2}
                            sx={{
                              width: 150,
                              height: 40,
                              boxShadow: "0px 3px 6px #00000029",
                            }}
                          >
                            Accept Deal
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Card>
          );
        })}
      </Box>
    </Box>
  );
};

export default ActiveDeals;
