import { Padding, Search } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled, useMediaQuery, useTheme } from "@mui/system";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../../../../../api/services";
import {
  getDealsDataById,
  GetPaymentRequestsByDeal,
} from "../../../../../../../api/services/deals";
import DealTabs from "../../../../DealTabs";
import RequestPaymentCard from "./mobile/RequestPaymentCard";
import RequestReceivedTable from "./RequestreceivedTable";
import { newDeal } from "../../../../../../../assets";

// Styled components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  marginBottom: "20px",
  color: "#7643EB",
  [theme.breakpoints.up("xs")]: {
    padding: "1%",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "2%",
  },
  [theme.breakpoints.up("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "30px",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "30px",
  },
}));

const StyledBreadcrumbs = styled(Breadcrumbs)({
  color: "#7643EB",
  fontSize: "18px",
  marginBottom: "0px",
});

const StyledTypography = styled(Typography)({
  fontFamily: "Mundial, sans-serif",
});

const StyledTextField = styled(TextField)({
  width: "300px",
  height: "40px",
  boxShadow: "0px 6px 14px #36408D08",
  border: "1px solid #2B2D221C",
  borderRadius: "10px",
  opacity: 1,
  fontSize: "16px",
  fontFamily: "Mundial, sans-serif",
});

// Utility function for formatting numbers to the Indian system

const RequestReceived = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { dealId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: PaymentRequests, isLoading } = useQuery(
    ["GetPaymentRequestsByDeal", dealId],
    GetPaymentRequestsByDeal
  );

  const { data: dealData }: any = useQuery(["deal", dealId], getDealsDataById, {
    onError: () => {
      navigate("/deal-process/deals/all");
    },
  });

  const data = dealData?.data;
  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);

  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.data?.id;
  const BankDetails = dealData?.data?.bankingDetails;

  const profileUser = data?.dealUsers.find(
    (user: any) => user?.userId === profileData?.data?.id
  );

  // Debounced search handler
  const handleSearch = debounce((value: string) => {
    setSearchTerm(value);
  }, 600);

  // Effect to reset current page when search term changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  // Memoized filtered data
  const filteredData: PaymentRequest[] = useMemo(() => {
    if (!PaymentRequests?.data?.data) return []; // Handle undefined or null case
    return PaymentRequests.data.data.filter(
      (request: any) =>
        !searchTerm ||
        request.amount.toString().includes(searchTerm.toLowerCase()) ||
        (request?.reasonForRequest &&
          request.reasonForRequest
            .toLowerCase()
            .includes(searchTerm.toLowerCase()))
    );
  }, [PaymentRequests?.data?.data, searchTerm]);

  // Pagination logic
  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle items per page change
  const handleChangeItemsPerPage = (value: number) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <StyledCard>
      <Box
        display="flex"
        alignItems="center"
        color="#7643EB"
        sx={{
          cursor: "pointer",
          mb: 0,
        }}
      >
        <IconButton size="medium" sx={{ mt: 0.5 }} onClick={() => navigate(-1)}>
          <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
        </IconButton>
        <StyledBreadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            color="#7643EB"
            href="/deal-process/deals/all"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "14px",
                xl: "14px",
                lg: "14px",
              },
            }}
          >
            Deals
          </Link>
          <Link
            underline="hover"
            color="#7643EB"
            href={`/deal-process/deals/${dealId}`}
            sx={{
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "14px",
                xl: "14px",
                lg: "14px",
              },
            }}
          >
            {dealId}
          </Link>
          {!isMobile && (
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}/truupe-account`}
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  xl: "14px",
                  lg: "14px",
                },
              }}
            >
              Truupe Account
            </Link>
          )}
          <Link
            underline="hover"
            color="#7643EB"
            href={`/deal-process/deals/${dealId}/request-received`}
            sx={{
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "14px",
                xl: "14px",
                lg: "14px",
              },
            }}
          >
            Payment Request
          </Link>
        </StyledBreadcrumbs>
      </Box>
      <Box>
        <DealTabs data={data} accountOwner={firstPerson} />
      </Box>
      <Divider sx={{ width: "100%", mb: 3, opacity: "0.5" }} />
      <Box
        sx={{
          padding: {
            xs: "3%",
            sm: "3%",
            md: 0,
            xl: 0,
            lg: 0,
          },
        }}
      >
        <StyledTypography variant="h4" my={"1vh"}>
          Payment Requests
        </StyledTypography>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          marginBottom="20px"
        >
          <Grid item xs={6} md={6}>
            <StyledTextField
              placeholder="Search by Amount, Reason"
              variant="outlined"
              size="small"
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <Search fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  fontFamily: "Mundial, sans-serif",
                  fontSize: "16px",
                },
                placeholderStyle: {
                  fontSize: "12px",
                  fontFamily: "Mundial, sans-serif",
                },
              }}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            ></Box>
          </Grid>
        </Grid>
        {viewMode === "table" && (
          <>
            {isMobile ? (
              paginatedData.length === 0 ? (
                <Box>
                  <Stack alignItems={"center"} gap={"1vh"} padding={"1vh"}>
                    <img width={"50%"} src={newDeal} alt="loading...." />
                    <Typography variant="h6">
                      It looks like you don't have any Requests yet.
                    </Typography>
                  </Stack>
                </Box>
              ) : (
                paginatedData.map((request: any, index: number) => (
                  <RequestPaymentCard
                    key={index}
                    userType={profileUser?.userType}
                    request={request}
                  />
                ))
              )
            ) : (
              <>
                {" "}
                <RequestReceivedTable
                  userType={profileUser?.userType}
                  paginatedData={paginatedData}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                  handleChangeItemsPerPage={handleChangeItemsPerPage}
                />
              </>
            )}
          </>
        )}
      </Box>
    </StyledCard>
  );
};

export default RequestReceived;
