import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

interface BankDetailsProps {
  bankData: any;
  mainData: any;
}

const stylesBefore = {
  width: "250px",
  height: "90px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "250px",
  height: "90px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

const BankDetails = ({ bankData, mainData }: BankDetailsProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Grid container spacing={{ xs: 2, md: 5 }}>
      {/* {mainData?.companyType === "SOLE_PROPRIETORSHIP" ||
      mainData?.companyType === "INDIVIDUAL" ? (
        <></>
      ) : (
        <Grid item xs={3} md={3} lg={3}>
          <Typography variant="caption">Beneficiary Name</Typography>
          <Typography variant="subtitle2">
            {bankData?.beneficiaryName}
          </Typography>
        </Grid>
      )} */}

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="caption">Beneficiary Name</Typography>
        <Typography variant="subtitle2">{bankData?.beneficiaryName}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="caption">Bank Name</Typography>
        <Typography variant="subtitle2">{bankData?.name}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="caption">Bank Account Number</Typography>
        <Typography variant="subtitle2">{bankData?.accountNumber}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="caption">IFSC Code</Typography>
        <Typography variant="subtitle2">{bankData?.ifscCode}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Typography variant="caption">Branch Name</Typography>
        <Typography variant="subtitle2">{bankData?.branch}</Typography>
      </Grid>

      {bankData?.relatedDocs?.map((document: any, index: number) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <FileUploadContainer
            typeId={bankData?.id}
            actions={<></>}
            data={document.subtype}
            typeData={document.type}
            name={document.originalFileName}
            url={document.url}
            subType={document.subtype}
            onFileChange={() => {}}
            showIcons={false}
            uploaded={true}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default BankDetails;
