import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import FileUploadContainer from "./FileUploadContainer";

const DocumentSelect = ({
  label,
  entityId,
  statusDetails,
  companyDocuments,
  firmAddressProof,
  handleDocumentChange,
  selectedDocument,
  filetype,
  onFileChange,
  documentSubtype,
}: any) => {
  const [disableSelect, setDisableSelect] = useState(false);
  const uploaded = !!companyDocuments?.filter(
    (i: any) => i?.subtype === documentSubtype
  ).length;
  const name = companyDocuments
    ?.filter((i: any) => i?.subtype === documentSubtype)
    .map((i: any) => i?.originalFileName);
  const url = companyDocuments
    ?.filter((i: any) => i?.subtype === documentSubtype)
    .map((i: any) => i?.url);

  useEffect(() => {
    const isValueFromAPI = companyDocuments?.some(
      (doc: any) => doc?.subtype === documentSubtype
    );
    // Disable the select dropdown if the value is fetched from firmDocuments
    if (isValueFromAPI) {
      setDisableSelect(true);
    } else {
      setDisableSelect(false);
    }
  }, []);

  return (
    <>
      <Typography variant="subtitle2" gutterBottom sx={{ marginTop: "15px" }}>
        {label}
      </Typography>

      <Box sx={{ width: "500px" }}>
        <Select
          size="small"
          sx={{
            width: "350px",
            height: "50px",
            "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select":
              {
                fontFamily: "mundial",
                color: "#2B2D22",
                fontSize: "14px",
              },
          }}
          id="documentSelect"
          value={selectedDocument || filetype}
          onChange={handleDocumentChange}
          displayEmpty
          disabled={disableSelect || statusDetails?.data?.documents}
          MenuProps={{
            PaperProps: {
              style: {
                fontSize: "20px",
                maxHeight: "400px",
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
        >
          <MenuItem disabled sx={{ fontSize: "14px" }}>
            {filetype ? filetype : <>Select Document</>}
          </MenuItem>
          {firmAddressProof?.map((document: any, index: any) => (
            <MenuItem key={index} value={document}>
              {document.replaceAll("_", " ")}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {(selectedDocument || statusDetails?.data?.documents || uploaded) && (
        <>
          <Typography
            variant="subtitle2"
            gutterBottom
            sx={{ marginTop: "10px" }}
          >
            {label} Document
          </Typography>
          <FileUploadContainer
            typeId={entityId}
            status={statusDetails?.data?.documents}
            actions={<></>}
            filetype={selectedDocument}
            data={documentSubtype}
            typeData="ENTITY"
            uploaded={uploaded}
            name={name}
            url={url}
            // onFileChange={onFileChange}
            onFileChange={(key) => {
              if (key === "") {
                setDisableSelect(false);
              } else {
                setDisableSelect(true);
              }
            }}
          />
        </>
      )}
    </>
  );
};

export default DocumentSelect;
