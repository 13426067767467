import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import { QueryClient, useQuery, useQueryClient } from "react-query";
import styled from "styled-components";

import { getEntityPreview } from "../../../../api/services";
import { Icons } from "../../../../assets";
import {
  formattedDate,
  maskAadharNumber,
  maskedPanNumber,
  truncateText,
} from "../../../../utils/previewUtils";
import FileUploadContainer from "../../../../components/shared/FileUploadContainer";
import BankingInformation from "../../../userProfile/banking-information/BankingInformation";

const SectionContainer = styled(Box)`
  padding: 20px;
`;

const SectionTitle = styled(Typography).attrs(() => ({
  variant: "h5",
  color: "black",
}))`
  padding-bottom: 16px;
`;

const stylesBefore = {
  width: "250px",
  height: "90px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};
const stylesAfter = {
  px: "1vw",
  width: "250px",
  height: "90px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

const InfoGrid = styled(Grid).attrs(() => ({
  container: true,
  spacing: 4,
  width: "100%",
  margin: 0,
  flexDirection: { xs: "column", md: "row" },
  "& .MuiGrid-root .MuiGrid-item": {
    paddingTop: 0,
    paddingLeft: 0,
  },
}))``;

const InfoItem = styled(Grid).attrs(() => ({
  item: true,
  xs: 3,
  border: "2px solid black",
  maxWidth: { xs: "100%", md: "25%" },
  "& .MuiGrid-root .MuiGrid-item": {
    paddingTop: 0,
    paddingLeft: 0,
  },
}))``;

const InfoText = styled(Typography).attrs(({ variant }) => ({
  variant: variant || "subtitle2",
}))``;

const DocumentBox = styled(Box)`
  width: 18vw;
  height: 10vh;
  border: 1px solid #1212121a;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

const DocumentInfo = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DocumentTitle = styled(Typography).attrs(() => ({
  variant: "body1",
  gutterBottom: true,
}))`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DocumentDate = styled(Typography).attrs(() => ({
  variant: "body1",
  gutterBottom: true,
}))`
  margin-top: 10px;
`;

interface PreviewComponentProps {
  kycType: any;
  data: any;
  EntityId: any;
}

export default function IndividualPreviewComponent({
  data,
  EntityId,
  kycType,
}: PreviewComponentProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { data: previewData, isLoading }: any = useQuery(
    ["get-user-id", EntityId],
    getEntityPreview
  );

  const queryClient = useQueryClient();

  const renderDirectorDocuments = (director: any) => {
    const directorDocuments = director?.documents || [];
    const documentTypes = [
      { type: "AADHAR_FRONT", label: "Aadhar Card Front" },
      { type: "AADHAR_BACK", label: "Aadhar Card Back" },
      { type: "PAN_FRONT", label: "PAN Front" },
      { type: "PAN_BACK", label: "PAN Back" },
      { type: "ADDRESS_PROOF_FRONT", label: "Address Proof Front" },
      { type: "ADDRESS_PROOF_BACK", label: "Address Proof Back" },
    ];

    return documentTypes.map((docType, idx) => {
      const doc = directorDocuments.find(
        (d: any) => d?.subtype === docType.type
      );
      return doc ? (
        <Grid item xs={12} sm={6} md={4} key={idx}>
          <FileUploadContainer
            typeId={data?.data?.id}
            actions={<></>}
            data={doc}
            typeData="ENTITY"
            name={doc.originalFileName}
            url={doc.subtype}
            subType={doc.subtype}
            showIcons={false}
            uploaded={true}
            onFileChange={() => {
              queryClient.invalidateQueries("get-user-id");
            }}
            {...(isMobile || isTablet
              ? {
                  stylesBefore: stylesBefore,
                  stylesAfter: stylesAfter,
                  shrinked: true,
                }
              : {})}
          />
        </Grid>
      ) : null;
    });
  };

  if (isLoading) {
    return (
      <Box
        display={"flex"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={"4rem"}></CircularProgress>;
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Accordion
        defaultExpanded
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">Individual Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid
            container
            sx={{
              justifyContent: "flex-start",
            }}
            spacing={{ xs: 2, md: 4 }}
            width="100%"
          >
            {[
              {
                label: "Individual Name",
                value: previewData?.data?.directors[0]?.name,
              },
              { label: "Email Address", value: data?.data?.emailAddress },
              {
                label: "Aadhar Number",
                value: maskAadharNumber(
                  previewData?.data?.directors[0]?.aadharNumber
                ),
              },
              {
                label: "PAN Number",
                value: maskedPanNumber(
                  previewData?.data?.directors[0]?.panNumber
                ),
              },
              {
                label: "Aadhar Status",
                value: previewData?.data?.directors[0]?.isAadharMismatch ? (
                  <Typography color="red">Mismatch</Typography>
                ) : (
                  <Typography color="green">Verified</Typography>
                ),
              },
            ].map((info, idx) => (
              <Grid item key={idx} xs={12} sm={6} md={4} lg={3}>
                <InfoText variant="caption">{info.label}</InfoText>
                <InfoText>{info.value}</InfoText>
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion
        defaultExpanded={!isMobile}
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">Banking Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ marginLeft: "-20px" }}>
            <BankingInformation data={previewData} isEditable={false} />
          </Box>
        </AccordionDetails>
      </Accordion>
      <Divider />
      <Accordion
        defaultExpanded={!isMobile}
        sx={{
          "&:hover": { backgroundColor: "transparent" },
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography variant="h5">Individual Documents</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {previewData?.data?.directors?.map((director: any, index: number) => (
            <React.Fragment key={index}>
              <Box sx={{ mt: 2, mb: 1 }}>
                <Typography variant="h6">{director?.name}</Typography>
              </Box>
              <Grid
                container
                sx={{
                  justifyContent: "flex-start",
                }}
                spacing={{ xs: 2, md: 4 }}
                width="100%"
              >
                {renderDirectorDocuments(director)}
              </Grid>
            </React.Fragment>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
