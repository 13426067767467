import {
  Apps,
  ArrowDropDown,
  Close,
  Search,
  TableRows,
  HighlightOff,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Grid,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import debounce from "lodash/debounce";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { getUserDeals } from "../../../../api/services/deals";
import { newDeal } from "../../../../assets";
import TuneIcon from "@mui/icons-material/Tune";
import DealsGrid from "./DealsGrid";
import DealsTable from "./DealsTable";
import { getusersbyid } from "../../../../api/services";
import { getUserId } from "../../../../utils/authUtils";
import Cookies from "js-cookie";

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  padding: "20px",
  marginBottom: "20px",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  height: "40px",
  boxShadow: "0px 6px 14px #36408D08",
  border: "1px solid #2B2D221C",
  borderRadius: "10px",
  opacity: 1,
  fontSize: "16px",
  fontFamily: "Mundial, sans-serif",
  margin: "0px",
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  width: "fit-content",
  height: "40px",
  border: "1px solid #1212121A",
  borderRadius: "5px",
  opacity: 0.6,
  boxShadow: "0px 6px 14px #36408D08",
  fontSize: "14px",
  fontFamily: "Mundial, sans-serif",
  color: "#1D1D1D",
  marginLeft: "10px",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  height: "38px",
  marginLeft: "10px",
  borderRadius: "8px",
}));

const getToggleButtonStyles = (isActive: boolean) => ({
  width: "35px",
  height: "35px",
  background: isActive ? "red" : "#FFFFFF",
  opacity: 1,
  border: "1px solid #1212121A",
  color: isActive ? "#FFFFFF" : "#000000",
});

const AllDeals = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const [currentPage, setCurrentPage] = useState(1);
  const [filterStatus, setFilterStatus] = useState<string[]>([]);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [viewMode, setViewMode] = useState<"table" | "grid">("table");

  const { data: dealData }: any = useQuery("user-deals", getUserDeals);

  const allDealsData = dealData?.data?.data || [];

  const statusOptions: string[] = [
    "Deal Created",
    "Invitation Issued",
    "Deal Accepted",
    "Deal Rejected",
    "Signature Pending",
    "Verification Pending",
    "Deal Cancelled",
    "Fee Pending",
    "Funds Awaited",
    "Funds Received",
    "Payment Verification",
    "Deal Completed",
  ];

  const debouncedSearchTerm = useMemo(
    () => debounce((value: string) => setSearchTerm(value), 600),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSearchTerm.cancel();
    };
  }, [debouncedSearchTerm]);

  const handleStatusChange = (event: any) => {
    const value = event.target.value;
    setFilterStatus(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeItemsPerPage = (value: number) => {
    setItemsPerPage(value);
  };

  const clearFilters = () => {
    setFilterStatus([]);
  };

  const filteredData = useMemo(
    () =>
      allDealsData.filter(
        (deal: any) =>
          (filterStatus.length === 0 || filterStatus.includes(deal.status)) &&
          (!searchTerm ||
            deal.code.toLowerCase().includes(searchTerm.toLowerCase()) ||
            deal.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            deal.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
            deal.amount.toString().includes(searchTerm) ||
            deal?.dealUsers?.some((user: any) =>
              user?.entity?.companyName
                ?.toLowerCase()
                .includes(searchTerm.toLowerCase())
            ))
      ),
    [allDealsData, filterStatus, searchTerm]
  );

  const totalItems = filteredData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <StyledCard>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        marginBottom={"20px"}
      >
        <Grid item width="100%">
          <Box
            display="flex"
            alignItems="center"
            sx={{
              flexDirection: { xs: "row", sm: "row", lg: "row", xl: "row" },
              justifyContent: {
                xs: "space-between",
                sm: "space-between",
                lg: "flex-start",
                xl: "flex-start",
              },
            }}
          >
            <StyledTextField
              sx={{
                width: { xs: "500px", sm: "500px", lg: "500px", xl: "500px" },
              }}
              placeholder="Search By Deal ID, Deal Title, Seller Entity Name, Buyer Entity Name"
              variant="outlined"
              size="small"
              onChange={(e) => debouncedSearchTerm(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton size="small">
                      <Search fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  fontFamily: "Mundial, sans-serif",
                  fontSize: "16px",
                },
                placeholderStyle: {
                  fontSize: "12px",
                  fontFamily: "Mundial, sans-serif",
                },
              }}
            />

            <Stack direction={"row"} alignItems={"center"}>
              <Box>
                <StyledSelect
                  multiple
                  value={filterStatus}
                  onChange={handleStatusChange}
                  variant="outlined"
                  IconComponent={ArrowDropDown}
                  displayEmpty
                  renderValue={(selected: any) =>
                    selected.length > 0 ? (
                      <Box display="flex" alignItems="center">
                        {isMobile || isTablet ? <TuneIcon /> : "Status"}
                        <Box
                          component="span"
                          borderRadius="50%"
                          color="#FFFFFF"
                          fontSize="10px"
                          marginLeft="5px"
                          width="15px"
                          height="15px"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          style={{
                            backgroundColor: theme.palette.primary.main,
                          }}
                        >
                          {selected.length}
                        </Box>
                      </Box>
                    ) : isMobile || isTablet ? (
                      <Box display="flex" alignItems="center">
                        <TuneIcon />
                      </Box>
                    ) : (
                      "Status"
                    )
                  }
                >
                  {statusOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                      <Checkbox checked={filterStatus.indexOf(option) > -1} />
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </StyledSelect>
              </Box>

              {filterStatus.length > 0 &&
                (isMobile || isTablet ? (
                  <HighlightOff
                    sx={{
                      fontSize: "x-large",
                      color: "red",
                      marginLeft: "1%",
                    }}
                    onClick={clearFilters}
                  />
                ) : (
                  <StyledButton variant="outlined" onClick={clearFilters}>
                    Clear Filters
                  </StyledButton>
                ))}
            </Stack>
          </Box>
        </Grid>
        {!isMobile && !isTablet && (
          <Grid item>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <ToggleButtonGroup
                value={viewMode}
                exclusive
                onChange={(e, newViewMode) =>
                  newViewMode && setViewMode(newViewMode)
                }
                sx={{ marginLeft: "20px" }}
              >
                <ToggleButton
                  value="table"
                  sx={{
                    ...getToggleButtonStyles(viewMode === "table"),
                    borderTopLeftRadius: "10px",
                    borderBottomLeftRadius: "10px",
                  }}
                >
                  <TableRows />
                </ToggleButton>
                <ToggleButton
                  value="grid"
                  sx={{
                    ...getToggleButtonStyles(viewMode === "grid"),
                    borderTopRightRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  <Apps />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Grid>
        )}
      </Stack>
      {paginatedData?.length <= 0 ? (
        <>
          {" "}
          <Box
            sx={{
              width: "100%",
              height: 400,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={newDeal}
              alt="No Deals"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "contain",
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontSize: { xs: "1.15rem", sm: "1.5rem", md: "1.75rem" },
                mb: 1,
                textAlign: "center",
              }}
            >
              No matches found
            </Typography>
          </Box>{" "}
        </>
      ) : (
        <>
          {paginatedData?.length <= 0 ? (
            <>
              {" "}
              <Box
                sx={{
                  width: "100%",
                  height: 400,
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={newDeal}
                  alt="No Deals"
                  style={{
                    width: "90%",
                    height: "90%",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontSize: { xs: "1.15rem", sm: "1.5rem", md: "1.75rem" },
                    mb: 1,
                    textAlign: "center",
                  }}
                >
                  No matches found
                </Typography>
              </Box>{" "}
            </>
          ) : (
            <>
              {isMobile || isTablet ? (
                <DealsGrid
                  paginatedData={paginatedData}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isDesktop={isDesktop}
                />
              ) : viewMode === "table" ? (
                <DealsTable
                  paginatedData={paginatedData}
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  itemsPerPage={itemsPerPage}
                  handleChangeItemsPerPage={handleChangeItemsPerPage}
                />
              ) : (
                <DealsGrid
                  paginatedData={paginatedData}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isDesktop={isDesktop}
                />
              )}
            </>
          )}
        </>
      )}
    </StyledCard>
  );
};

export default AllDeals;
