import { Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DealDeclined from "../../modalCards/DealDeclined";

export default function InvitationCancelled({ data, secondUser }: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Stack gap={"20px"} direction={"column"}>
        <Typography variant="h2" sx={{ fontWeight: "500" }}>
          Deal Cancelled
        </Typography>
        <Typography
          variant="caption"
          sx={{
            fontWeight: "400",
            mt: -1,
          }}
        >
          The Trustee Authority has cancelled your deal, You can view the reason
          provided by the Trustee for cancelling the deal. This will allow you
          to understand their decision and determine if you would like to revise
          the deal and continue the process.
        </Typography>
      </Stack>
      <DealDeclined open={isModalOpen} onClose={handleClose} data={data} />
      <Button
        variant="contained"
        sx={{ width: "auto", my: "20px" }}
        onClick={handleOpen}
      >
        View Reason
      </Button>
    </Box>
  );
}
