import {
  Box,
  Breadcrumbs,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getEntityDetails, getusersbyid } from "../../api/services";
// import ApprovalBar from "./entity-components/ApprovalBar";
import BankingInformation from "./banking-information/BankingInformation";
import SignatoryDocumentInformation from "./signatories-information/SignatoryDocumentInformation";
import DirectorInformation from "./signatories-information/DirectorInformation";
import EntityInformation from "./entity-information/EntityInformation";
import EntityDocuments from "./entity-information/EntityDocuments";
import Signatories from "./signatories-information/Signatories";
import styled from "styled-components";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Cookies from "js-cookie";
import { newDeal } from "../../assets";
import SignatoryAuthorityDocument from "./signatories-information/SignatoryAuthorityDocument";
import { getUserId } from "../../utils/authUtils";

const UserProfile: React.FC = () => {
  const navigate = useNavigate();
  // const entityId = Cookies.get("entityId") || "";
  const userID = getUserId();

  const {
    data: entityData,
    isLoading: isEntityLoading,
    isError: isEntityError,
  } = useQuery(["getUser-id", userID], getusersbyid);

  const entityId = entityData?.data?.entities[0]?.id;

  useEffect(() => {
    if (entityId === undefined) {
      Cookies.remove("entityId");
    } else {
      Cookies.set("entityId", entityId);
    }
  }, [entityId]);

  const { data, isLoading, isError } = useQuery(
    ["entity", entityId],
    () => getEntityDetails(entityId),
    {
      enabled: !!entityId, // Only enable query if entityId has a value
    }
  );

  const EntityStatus = data?.data?.entityStatus;
  const signatoryDirectors = data?.data?.signatoryDirectors || [];
  const firmDocuments = data?.data?.firmDocuments || [];

  if (isLoading || isEntityLoading) return <LinearProgress />;
  if (isError || isEntityError) return <p>Error fetching data...</p>;

  const BackButtonContainer = styled(Box)`
    padding: 3vh 0;
    display: flex;
    flex-direction: row;
  `;

  if (EntityStatus === undefined || EntityStatus === "In Progress") {
    return (
      <>
        <Card
          sx={{
            margin: "0 auto",
            padding: "20px",
            borderRadius: "10px",
            marginTop: "20px",
            width: "calc(100% - 40px)",
          }}
        >
          <BackButtonContainer>
            <IconButton
              size="medium"
              sx={{ mt: -0.5 }}
              onClick={() => navigate("/home")}
            >
              <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
            </IconButton>
            <Breadcrumbs
              aria-label="breadcrumb"
              sx={{ color: "#7643EB", fontSize: "18px", marginTop: "5px" }}
            >
              <Link underline="hover" color="#7643EB" href="/home">
                Back
              </Link>
            </Breadcrumbs>
          </BackButtonContainer>
          <Box
            sx={{
              width: "100%",
              height: 400,
              display: "flex",
              flexDirection: "column",
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "20px",
            }}
          >
            <img
              src={newDeal}
              alt="No Deals"
              style={{
                width: "90%",
                height: "90%",
                objectFit: "contain",
              }}
            />
            <Typography variant="h3">
              Please submit KYB/KYC to view Entity Details
            </Typography>
          </Box>
        </Card>
      </>
    );
  }

  return (
    <>
      <Card
        sx={{
          margin: "0 auto",
          padding: "20px",
          borderRadius: "10px",
          marginTop: "20px",
          width: "calc(100% - 40px)",
        }}
      >
        <BackButtonContainer>
          <IconButton
            size="medium"
            sx={{ mt: -0.5 }}
            onClick={() => navigate("/home")}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px", marginTop: "5px" }}
          >
            <Link underline="hover" color="#7643EB" href="/home">
              Back
            </Link>
          </Breadcrumbs>
        </BackButtonContainer>
        <EntityInformation data={data} />

        {/* <DirectorInformation data={directors} mainData={data} /> */}
        {data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <>
            <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />
            <Signatories
              mainData={data}
              signatoryDirectors={signatoryDirectors}
            />
          </>
        )}

        <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />

        <BankingInformation data={data} isEditable={true} />

        <Divider sx={{ my: 3, width: "calc(100% + 40px)", ml: "-20px" }} />

        {data?.data?.companyType === "INDIVIDUAL" ? (
          <></>
        ) : (
          <>
            <EntityDocuments
              data={firmDocuments}
              mainData={data?.data}
              title={"Firm Documents"}
            />
          </>
        )}
        <SignatoryDocumentInformation mainData={data} isSupporting={false} />
        {signatoryDirectors?.map((director: any, index: number) => (
          <React.Fragment key={index}>
            <SignatoryAuthorityDocument
              key={index}
              data={data?.data?.signatoryDirectors[index]}
              currentDirectorCount={index + 1}
            />
            {index !== signatoryDirectors?.length - 1 && (
              <Divider sx={{ marginY: 2 }} />
            )}
          </React.Fragment>
        ))}
      </Card>
    </>
  );
};

export default UserProfile;
