import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import { Icons } from "../../../assets";
import FileUploadContainer from "../../../components/shared/FileUploadContainer";

const stylesBefore = {
  width: "280px",
  height: "100px",
  padding: "15px 0px",
  border: `2px dashed #3333334D`,
  borderRadius: "12px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  userSelect: "none",
};

const stylesAfter = {
  px: "1vw",
  width: "280px",
  height: "100px",
  padding: "10px",
  border: "1px solid #1212121A",
  borderRadius: "10px",
  opacity: 1,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: "#FBFBFB",
};

interface RejectedDocsGridProps {
  title: string;
  documents: any[];
  onFileChange: (file: any) => void;
  fileData: any;
  rejectedDocIds: string;
}

export default function RejectedDocsGrid({
  title,
  documents,
  onFileChange,
  rejectedDocIds,
  fileData,
}: RejectedDocsGridProps) {
  if (!documents || documents?.length === 0) return null;

  const rejectedIdsSet = new Set(
    rejectedDocIds?.split(",")?.map((id) => Number(id?.trim()))
  );

  const rejectedDocs = documents?.filter((doc: any) =>
    rejectedIdsSet?.has(doc.id)
  );

  return (
    <Box width="96%" mb={5}>
      <Typography variant="subtitle1">{title}</Typography>
      <Divider sx={{ width: "100%", mb: 2 }} />
      <Grid container spacing={3} ml={{ md: "4px" }}>
        {rejectedDocs.length === 0 ? (
          <Grid item xs={12}>
            <Stack direction="row" alignItems="center" gap="5px">
              <Typography variant="subtitle2">
                All documents verified
              </Typography>
              <img height="20px" src={Icons.greenCheck} alt="loading..." />
            </Stack>
          </Grid>
        ) : (
          rejectedDocs.map((doc: any, index: number) => (
            <Grid item xs={12} sm={12} md={12} lg={12} key={index}>
              <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography variant="caption">
                    Rejected Document - {doc.subtype}
                  </Typography>
                  <FileUploadContainer
                    typeId={doc.typeId}
                    actions={<></>}
                    data={doc.subtype}
                    typeData={doc.type}
                    name={doc.originalFileName}
                    url={doc.url}
                    onFileChange={onFileChange}
                    showIcons={false}
                    uploaded={true}
                    stylesBefore={stylesBefore}
                    stylesAfter={stylesAfter}
                  />
                </Box>
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "4px" }}
                >
                  <Typography variant="caption">Upload New Document</Typography>
                  <FileUploadContainer
                    typeId={null}
                    actions={<></>}
                    data={doc.subtype}
                    typeData={doc.type}
                    onFileChange={onFileChange}
                    stylesBefore={stylesBefore}
                    stylesAfter={stylesAfter}
                    fileData={(data) => {
                      if (data) {
                        const mediaId = String(data?.id);
                        const subtype = data?.subtype;
                        fileData(subtype, mediaId, doc.typeId);
                      }
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
}
