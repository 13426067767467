import { Box, Button, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checked, clock } from "../../../../../assets";
import Status from "../dealStatus/DealStatus";

interface Signee {
  name?: string;
  time?: string;
  status?: string;
}

// Styled components
const StyledCard = styled(Box)(({ theme }) => ({
  boxShadow: "none",
  padding: "25px",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "2%",
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: "2%",
  },
}));

const SigneeBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "50%",
  width: 36,
  height: 36,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const SignButton = styled(Button)({
  width: "100px",
  boxShadow: "none",
  borderRadius: "5px",
  mb: 2,
  cursor: "pointer",
});

const PayButton = styled(Button)({
  width: "100px",
  boxShadow: "none",
  borderRadius: "5px",
  mb: 2,
  cursor: "pointer",
});

const SigneeCard = ({
  data,
  needed,
}: {
  signee?: Signee;
  showSignButton?: boolean;
  data?: any;
  accountOwner?: any;
  needed?: any;
  dealCreator?: any;
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  // const [openModel, setOpenModel] = useState(false);
  const [loading, setLoading] = useState(false);

  const payNow = data?.dealUsers.find(
    (i: any) => i?.userType === "Buyer" && i?.userId === needed?.data?.id
  );

  // const { mutate: payServiceBipass } = useMutation(createOrderBipass, {
  //   onSuccess: (res: any) => {
  //     // toast.success(res?.data?.message);
  //     setOpen(true);
  //     setLoading(false);
  //   },
  //   onError: (error: any) => {
  //     toast.error(error?.response?.data?.message);
  //     setLoading(false);
  //   },
  // });

  // const handlePayPlatformFee = () => {
  //   setLoading(true);
  //   payServiceBipass({
  //     code: data?.code,
  //   });
  // };

  const Payment = () => {
    navigate(`/deal-process/deals/${data?.code}/platform-fee`, {
      replace: true,
    });
  };

  const isDealCancelled =
    data?.statusHistory?.find(
      (entry: any) => entry?.status === "Deal Cancelled"
    )?.status === "Deal Cancelled";

  localStorage.setItem("dealcode", data?.code);

  const renderSignButton = (i: any, index: any) =>
    !isDealCancelled &&
    i?.status === "Pending" &&
    i?.user?.id === needed?.data?.id &&
    (index === 0 ||
      index === 1 ||
      data?.dealSignatures[index - 1]?.status === "Completed") && (
      <SignButton
        variant="contained"
        color="primary"
        onClick={() => window.open(i?.signUrl, "_self")}
      >
        Sign
      </SignButton>
    );

  return (
    <StyledCard
      sx={{
        width: { xs: "100%", sm: "100%", md: "32vw", lg: "32vw", xl: "32vw" },
      }}
    >
      <Typography
        variant="h4"
        sx={{
          color: "#151515",
          textTransform: "capitalize",
          marginBottom: "25px",
          fontWeight: 500,
        }}
      >
        Deal Signatures
      </Typography>

      {data?.dealSignatures.map((i: any, index: any) => (
        <Box key={i?.userId || i?.preSignResponse?.displayName} mb={3}>
          <Typography
            variant="h6"
            sx={{
              color: "#151515",
              textTransform: "capitalize",
              marginBottom: "10px",
              fontWeight: 400,
            }}
          >
            Signee {index + 1}
          </Typography>
          <Grid
            container
            spacing={1}
            alignItems="center"
            marginBottom="10px"
            justifyContent={{
              xs: "space-between",
              sm: "flex-start",
              md: "flex-start",
              xl: "flex-start",
              lg: "flex-start",
            }}
            width={"100%"}
          >
            <Grid item>
              <SigneeBox>
                <img
                  src={i?.status === "Pending" ? clock : checked}
                  alt={i?.status === "Pending" ? "Clock Icon" : "Checked Icon"}
                  style={{ width: "50%", height: "auto" }}
                />
              </SigneeBox>
            </Grid>
            <Grid item xs={7} sm={5} md={7} lg={7} xl={7}>
              <Box>
                <Typography variant="subtitle2">
                  {i?.preSignResponse?.displayName}
                </Typography>
                {(i?.status !== "Pending" || isDealCancelled) && (
                  <Typography variant="caption">
                    {moment(i?.updatedAt).format("DD/MM/YYYY, hh:mm A")}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
              {isDealCancelled && i?.status === "Pending" ? (
                <Status variant={"Deal Cancelled"} />
              ) : (
                <Status variant={i?.status} />
              )}
            </Grid>
          </Grid>
          {renderSignButton(i, index)}
        </Box>
      ))}

      {data?.dealSignatures[3]?.status === "Completed" &&
        payNow &&
        (data?.status === "Verification Pending" ||
          data?.status === "Fee Pending") && (
          <>
            {/* <Button
              variant="contained"
              onClick={handlePayPlatformFee}
              sx={{ width: "auto", mt: 2 }}
              disabled={loading} // Disable the button while loading
            >
              {loading ? <CircularProgress size={24} /> : "Pay Platform Fee"}
            </Button> */}
            {/* <Congrats open={open} inDealDocument={true} /> */}
            <PayButton variant="contained" color="primary" onClick={Payment}>
              <Typography variant="body2" sx={{ opacity: 1, color: "#FFFFFF" }}>
                Pay platform fee
              </Typography>
            </PayButton>
          </>
        )}
    </StyledCard>
  );
};

export default SigneeCard;
