import CloseIcon from "@mui/icons-material/Close";
import { Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { getUserProfile } from "../../../../../api/services";
import { acceptDeal } from "../../../../../api/services/deals";

import { CustomLoadingButton } from "../../../../../components/buttons/SubmitLoaderButton";
import SignatoryDirectors from "../../createDeal/SignatoryDirectors";
import BankingDetails from "../../createDeal/DealBankingInformation";
// import AcceptDealSignatoryDirectors from "./AcceptDealSignatoryDirector";

interface ApproveModelProps {
  open: boolean;
  onClose: () => void;
  data?: any;
  secondUser?: any;
  deal?: any;
}

const ApproveModel: React.FC<ApproveModelProps> = ({
  open,
  onClose,
  data,
  secondUser,
  deal,
}) => {
  const navigate = useNavigate();

  const { mutate: accept, isLoading } = useMutation(acceptDeal, {
    onSuccess: (res: any) => {
      toast.success("Deal accepted successfully");
      navigate(`/deal-process/deals/deal-accepted/${data?.code}`);
      onClose();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { data: profileData }: any = useQuery("getUser-id", getUserProfile);
  const [signDirector, setSignDirector] = useState();
  const [bankingId, setBankingId] = useState("");

  // const userBankingInformation =
  //   profileData?.data?.entities[0]?.bankingInformation[0];
  const bankingData = profileData?.data?.entities[0]?.bankingInformation;

  const signatoryDirectors = profileData?.data?.entities[0]?.directors?.filter(
    (item: any) => item.isSignatoryAuthority
  );

  const handleAccept = () => {
    if (data?.code) {
      accept({
        data: { bankingId: bankingId, signatoryId: signDirector },
        id: data?.code,
      });
    } else {
      accept({
        data: { bankingId: bankingId, signatoryId: signDirector },
        id: deal?.code,
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: "55vw",
          width: {
            xs: "80%",
            sm: "80%",
            md: "auto",
            lg: "auto",
            xl: "auto",
          },
          bgcolor: "background.paper",
          border: "1px solid #1212121A",
          borderRadius: "15px",
          boxShadow: 24,
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: -40,
            right: 0,
            bgcolor: "background.paper",
            borderRadius: "50%",
            height: 25,
            width: 25,
          }}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <BankingDetails
          bankingData={bankingData}
          setBankingId={setBankingId}
          profile={profileData?.data}
        />
        <SignatoryDirectors
          directorsData={signatoryDirectors}
          setSignDirector={setSignDirector}
          profile={profileData?.data}
        />

        <Box mb={"1vh"} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <CustomLoadingButton
            onClick={handleAccept}
            title={`Submit`}
            submitLoader={isLoading}
            width={"120px"}
            marginRight={0}
          ></CustomLoadingButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApproveModel;
