import { Button, Divider, Grid, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getDealsDataById } from "../../../../../api/services/deals";
import { Icons } from "../../../../../assets";

function DealAccepted() {
  const { dealId } = useParams();

  const { data: dealData }: any = useQuery(["deal", dealId], getDealsDataById);

  const data = dealData?.data;

  const navigate = useNavigate();

  const secondUser = data?.dealUsers?.find(
    (user: any) => user?.userId !== data?.createdBy?.id
  );

  return (
    <Box
      sx={{
        height: "83vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Card
        sx={{
          width: "50vw",
          height: "auto",
          px: "25px",
          py: "30px",
        }}
      >
        <Stack display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <img
            src={Icons.checked}
            alt="Check Icon"
            style={{
              width: "80px",
              height: "80px",
              opacity: 1,
            }}
          />
          <Typography
            sx={{
              fontSize: "30px",
            }}
          >
            Deal Accepted Successfully!
          </Typography>
          <Typography
            sx={{
              mx: "20px",
              textAlign: "center",
              fontWeight: 300,
              fontSize: "15px",
              color: "#333333",
              opacity: 0.85,
              my: "20px",
            }}
          >
            Thank you for accepting the deal! You can view the details of the
            accepted deal, and take the next steps to configure the Deal
            document.
          </Typography>
        </Stack>
        <Divider></Divider>
        <Typography my={"20px"}>Deal Details</Typography>

        <Grid container my={"20px"} spacing={3}>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Deal ID
              </Typography>
              <Typography sx={{ fontSize: "16px", color: "#7643EB" }}>
                {data?.code}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Deal Title
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>{data?.title}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Type of deal
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>{data?.type}</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid container my={"20px"} spacing={3}>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Duration
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {data?.duration} {data?.durationType}s
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Role
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {secondUser?.userType === "Buyer" ? "Seller" : "Buyer"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Deal Amount
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>{data?.amount}</Typography>
            </Stack>
          </Grid>
        </Grid>

        <Divider></Divider>

        <Typography my={"20px"}>Deal Party Information</Typography>

        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Deal Role
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {secondUser?.userType}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                {secondUser?.userType} Name
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {secondUser?.user?.name}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                {secondUser?.userType} Email Address
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {secondUser?.user?.emailAddress}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Entity Name
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {secondUser?.entity?.companyType}
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={4}>
            <Stack>
              <Typography sx={{ fontSize: "14px", color: "grey" }}>
                Business Category{" "}
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                {secondUser?.entity?.category}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        <Box marginTop={"8vh"} display={"flex"} justifyContent={"center"}>
          <Button
            onClick={() => {
              navigate(`/deal-process/deals/${data?.code}`, { replace: true });
            }}
            variant="contained"
          >
            View Deal Dashboard
          </Button>
        </Box>
      </Card>
    </Box>
  );
}

export default DealAccepted;
