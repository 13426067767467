import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/system";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { Icons } from "../../../../../assets";
import { WidthFull } from "@mui/icons-material";

const StyledModalBox = styled(Box)(({ theme }) => ({
  position: "absolute" as "absolute",
  [theme.breakpoints.up("xs")]: {
    width: "80%",
    top: "5%",
    left: "10%"
  },
  [theme.breakpoints.up("sm")]: {
    width: "80%",
    top: "12%",
    left: "10%"
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
    top: "10%",
    left: "20%"
  },
  [theme.breakpoints.up("lg")]: {
    width: "50%",
    top: "5%",
    left: "25%"
  },
  [theme.breakpoints.up("xl")]: {
    width: "50%",
    top: "5%",
    left: "25%"
  }
}));

type HowToAddFundsModalProps = {
  openModel: boolean;
  setOpenModel: (isOpen: boolean) => void;
  data: any;
};

export default function HowToAddFundsModal({
  openModel,
  setOpenModel,
  data,
}: HowToAddFundsModalProps) {
  const [copied, setCopied] = useState(false); // State to manage copied status

  const handleClose = () => {
    setOpenModel(false);
  };

  const copyAllDetailsToClipboard = () => {
    const details = `
      Account Name: Savcity Technologies Private Limited, 
      Account Number: ${data?.bankingDetails?.accountNumber}, 
      IFSC Code: ${data?.bankingDetails?.ifscCode}
    `;
    navigator.clipboard.writeText(details).then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3500);
    });
  };

  const accountDetails = [
    {
      label: "Account Name",
      value: "Savcity Technologies Private Limited",
    },
    {
      label: "Account Number",
      value: data?.bankingDetails?.accountNumber,
    },
    {
      label: "IFSC Code",
      value: data?.bankingDetails?.ifscCode,
    },
  ];

  return (
    <Box>
      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <StyledModalBox>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                my: "1vh",
              }}
            >
              <IconButton
                sx={{ bgcolor: "white", size: "small" }}
                onClick={handleClose}
              >
                <CloseIcon sx={{ fontSize: "1rem" }} />
              </IconButton>
            </Box>

            <Box
              sx={{
                bgcolor: "background.paper",
                boxShadow: 24,
                width: "100%",
                p: 4,
                borderRadius: "5px",
                overflowY: "scroll",
                maxHeight: {
                  xs:  "35pc",
                  sm:  "35pc",
                  md:  "40pc",
                  xl:  "45pc",
                  lg:  "45pc",
                }
              }}
            >
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ marginTop: "1vh", fontWeight: 500 }}
                >
                  STEPS TO FOLLOW TO ADD FUNDS INTO DEAL ACCOUNT
                </Typography>
                <Box sx={{ marginTop: "16px" }}>
                  {[
                    "Log into your Internet banking.",
                    "Add Beneficiary using the details below (you can use the copy button to copy all the details)",
                  ].map((step, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <Stack>
                        <Typography variant="subtitle2">
                          Step {index + 1}
                        </Typography>
                        <Typography variant="body1">{step}</Typography>
                      </Stack>
                    </Box>
                  ))}
                </Box>
              </Stack>
              <Stack>
                <Typography
                  variant="h6"
                  sx={{ marginBottom: "8px", fontWeight: 500 }}
                  id="payment-details-modal-title"
                >
                  How to Add Funds
                </Typography>
                <Divider />
                <Typography variant="h6" sx={{ my: "1vh", fontWeight: 500 }}>
                  TRUUPE ACCOUNT DETAILS
                </Typography>
                {accountDetails.map((detail, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "16px",
                    }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography variant="caption">{detail.label}</Typography>
                      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
                        {detail.value.toUpperCase()}
                      </Typography>
                    </Box>
                  </Box>
                ))}
                <Typography
                  variant="subtitle2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "primary.main",
                    cursor: "pointer",
                    marginBottom: "16px",
                  }}
                  onClick={copyAllDetailsToClipboard}
                >
                  <ContentCopyIcon
                    fontSize="small"
                    sx={{ marginRight: "4px" }}
                  />
                  {copied ? "Copied!" : "Copy All Details"}
                </Typography>
              </Stack>
              <Divider />
              <Accordion
                defaultExpanded
                sx={{
                  "&:hover": { backgroundColor: "transparent" },
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="subtitle2">
                    TERMS & CONDITIONS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {[
                    "The bank account details you provide for the transfer must exactly match the buyer entity's bank account details",
                    "The amount you transfer must not exceed the agreed-upon deal amount under any circumstances.",
                    "If we encounter any difficulties or irregularities during the transfer process, or if the transaction cannot be completed for any reason, the full amount will be credited back to your original account.",
                  ].map((step, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "8px",
                      }}
                    >
                      <img
                        src={Icons.right}
                        alt="right icon"
                        style={{
                          width: "24px",
                          height: "24px",
                          marginRight: "8px",
                        }}
                      />
                      <Typography variant="caption">{step}</Typography>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Box>
          </StyledModalBox>
        </>
      </Modal>
    </Box>
  );
}
