import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IosShareRoundedIcon from "@mui/icons-material/IosShareRounded";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getUserProfile } from "../../../../../../api/services";
import {
  dealtransaction,
  getDealsDataById,
} from "../../../../../../api/services/deals";
import DealInformation from "./Dealinformation";

import AccountCard from "../accountCard/Escrowcard";
import EscrowTransactionsCards from "../accountCard/EscrowTransactionsCards.tsx";
import TransactionTableSortable from "../transactions/ledger/TransactionTableSortable";
import { useEffect } from "react";
import DealTabs from "../../../DealTabs";

// Styled components
const StyledBox = styled(Box)({
  minHeight: "83vh",
});

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: "10px",
  padding: "25px",
  [theme.breakpoints.up("xs")]: {
    padding: "1%",
  },
  [theme.breakpoints.up("sm")]: {
    padding: "2%",
  },
  [theme.breakpoints.up("md")]: {
    padding: "25px",
  },
  [theme.breakpoints.up("lg")]: {
    padding: "25px",
  },
  [theme.breakpoints.up("xl")]: {
    padding: "25px",
  },
}));

const StyledBreadcrumbs = styled(Breadcrumbs)({
  color: "#7643EB",
  fontSize: "18px",
});

const StyledButton = styled(Button)({
  // mx: "2vw",
  marginBottom: "1vh",
});

const EscrowTransactions = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const { dealId } = useParams();
  const { data: profileData } = useQuery("getUser-id", getUserProfile);
  const { data: transactionData, isLoading: isLoad } = useQuery(
    ["dealTransactions", dealId],
    dealtransaction
  );
  const { data: dealData, isLoading } = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deal-process/deals/all");
      },
    }
  );

  const data = dealData?.data;
  const dealCreator = data?.dealUsers?.find(
    (user: any) => user?.userId === data?.createdBy?.id
  );
  const firstPerson = dealCreator?.userId === profileData?.data?.id;
  const BankDetails = dealData?.data?.bankingDetails;

  const isBuyer =
    dealData?.data?.dealUsers?.find(
      (user: any) => user?.userId === profileData?.data?.id
    )?.userType === "Buyer";

  if (isLoading || isLoad) {
    return <LinearProgress color="primary" />;
  }

  return (
    <StyledBox>
      <StyledCard>
        <Box
          display="flex"
          alignItems="center"
          color="#7643EB"
          sx={{ cursor: "pointer" }}
        >
          <IconButton
            size="medium"
            sx={{ mt: 0.5 }}
            onClick={() => navigate(-1)}
          >
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <StyledBreadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              color="#7643EB"
              href="/deal-process/deals/all"
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  xl: "14px",
                  lg: "14px",
                },
              }}
            >
              Deals
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}`}
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  xl: "14px",
                  lg: "14px",
                },
              }}
            >
              {dealId}
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}/truupe-account`}
              sx={{
                fontSize: {
                  xs: "12px",
                  sm: "14px",
                  md: "14px",
                  xl: "14px",
                  lg: "14px",
                },
              }}
            >
              Truupe Account
            </Link>
          </StyledBreadcrumbs>
        </Box>
        <Box>
          <DealTabs data={data} accountOwner={firstPerson} />
        </Box>
        <Divider sx={{ width: "100%", mb: 3, opacity: "0.5" }} />

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
            gap: 3,
            height: "100%",
            padding: {
              xs: "3%",
              sm: "3%",
              md: 0,
              xl: 0,
              lg: 0,
            },
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="h4" align="left">
              Account Summary
            </Typography>
            <AccountCard bankDetails={BankDetails} dealData={dealData} />
          </Box>

          <Box sx={{ flex: 2 }}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h4" align="left" sx={{ mb: 2 }}>
                Deal Information
              </Typography>
              {/* {(isBuyer || true) && (
                <StyledButton
                  variant="contained"
                  onClick={() => {
                    navigate(`/deal-process/deals/${dealId}/request-received`);
                  }}
                >
                  {isMobile ? " View Request" : " View Payment Request"}
                </StyledButton>
              )} */}
            </Box>
            <DealInformation dealData={dealData} profileData={profileData} />
          </Box>
        </Box>

        <Box>
          <EscrowTransactionsCards dealData={dealData} dealId={dealId} />
        </Box>

        <Box
          sx={{
            display: { xs: "none", sm: "block", lg: "block", xl: "block" },
          }}
        >
          <Box sx={{ padding: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h3" align="left" sx={{ mb: 2 }}>
                Transactions
              </Typography>
              {/* <Button variant="outlined">
                <IosShareRoundedIcon sx={{ fontSize: 18, mr: 1 }} />
                Export
              </Button> */}
            </Box>
          </Box>
          <TransactionTableSortable
            dealData={dealData?.data}
            transactionData={transactionData}
          />
        </Box>
        <Box
          width={"100%"}
          justifyContent={"center"}
          sx={{
            display: { xs: "flex", sm: "none", lg: "none", xl: "none" },
            marginBottom: { xs: "5%", sm: "0px", lg: "0px", xl: "0px" },
          }}
        >
          <Button
            sx={{ width: "200px" }}
            variant="contained"
            onClick={() => {
              navigate(
                `/deal-process/deals/${dealId}/truupe-account/transactions`
              );
            }}
          >
            {" "}
            View Transactions
          </Button>
        </Box>
      </StyledCard>
    </StyledBox>
  );
};

export default EscrowTransactions;
