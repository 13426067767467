import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatIndianIntegers } from "../../../../../../utils/dealFunctions";
import DealSignStatus from "../../dealInformation/DealSignStatus";
import Status from "../../dealStatus/DealStatus";

type DealInformationProps = {
  dealData: any;
  profileData: any;
};

function DealInformation({ dealData, profileData }: DealInformationProps) {
  const navigate = useNavigate();

  const OtherUser = dealData?.data?.dealUsers?.filter(
    (user: any) => user?.userId !== profileData?.data?.id
  );

  const ProfileUser = dealData?.data?.dealUsers?.filter(
    (user: any) => user?.userId === profileData?.data?.id
  );

  return (
    <Box>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            height: "auto",
            backgroundColor: "#F3EFFE",
            borderRadius: "8px 8px 0 0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "15px 20px",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ alignItems: "center", justifyContent: "space-between" }}
          >
            <Grid item xs={4}>
              <Typography
                variant="h5"
                sx={{
                  color: "#7643EB",
                }}
              >
                {dealData?.data?.code}
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <Typography variant="h5">
                Deal Duration : {dealData?.data?.duration} Months
              </Typography>
            </Grid>

            <Grid
              item
              xs={4}
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              {}
              <Box>
                {dealData?.data?.status === "Funds Received" ? (
                  ProfileUser[0]?.userType === "Buyer" ? (
                    <Status variant={"Initiate Payment"} />
                  ) : (
                    <Status variant={"Request Payment"} />
                  )
                ) : dealData?.data?.status === "Payment Verification" ? (
                  ProfileUser[0]?.userType === "Buyer" ? (
                    <Status variant={"Under Verification"} />
                  ) : (
                    <Status variant={"Under Verification"} />
                  )
                ) : (
                  <Status variant={dealData?.data?.status} />
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            width: "100%",
            padding: "10px 20px",
          }}
        >
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="caption">Deal Title</Typography>
              <Typography variant="subtitle2">
                {dealData?.data?.title}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Typography variant="caption">Deal Type</Typography>
              <Typography variant="subtitle2">
                {dealData?.data?.type}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={4}>
              <Typography variant="caption">Deal Amount</Typography>
              <Typography variant="subtitle2">
                {"  ₹  "}
                {formatIndianIntegers(dealData?.data?.amount)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ my: "1.2vh", mx: "1vw" }} />
        <Box
          sx={{
            width: "100%",
            padding: "0px 20px",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="caption">
                {OtherUser[0]?.userType || ""} Name
              </Typography>
              <Typography variant="subtitle2">
                {OtherUser[0]?.user?.name || ""}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="caption">
                {OtherUser[0]?.userType || ""} Email Address
              </Typography>
              <Typography variant="subtitle2">
                {OtherUser[0]?.user?.emailAddress || ""}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              <Typography variant="caption">
                {OtherUser[0]?.userType || ""} Entity Name
              </Typography>
              <Typography variant="subtitle2">
                {OtherUser[0]?.entity?.companyType === "INDIVIDUAL" ? (
                  <>{OtherUser[0]?.entity?.directors[0]?.name || ""}</>
                ) : (
                  <> {OtherUser[0]?.entity?.companyName || ""}</>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Divider sx={{ marginTop: "1.2vh", mx: "1vw" }} />

        <Grid container display={"flex"} justifyContent={"space-around"}>
          <Grid
            item
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <DealSignStatus />
          </Grid>
          <Grid
            item
            display={"flex"}
            sx={{
              padding: "0px",
              width: { xs: "100%", sm: "100%", lg: "auto", xl: "auto" },
            }}
            justifyContent={"center"}
          >
            {ProfileUser[0]?.userType === "Buyer" ? (
              <>
                <Button
                  disabled={dealData?.data?.status === "Deal Completed"}
                  onClick={() =>
                    navigate(
                      `/deal-process/deals/${dealData?.data?.code}/initiate-payment`
                    )
                  }
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "200px",
                    my: {
                      xs: "10px",
                      sm: "10px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                >
                  initiate payment
                </Button>
              </>
            ) : (
              <>
                {/* <Button
                  disabled={dealData?.data?.status === "Deal Completed"}
                  onClick={() =>
                    navigate(
                      `/deal-process/deals/${dealData?.data?.code}/request-payment`
                    )
                  }
                  variant="contained"
                  color="primary"
                  sx={{
                    width: "200px",
                    my: {
                      xs: "10px",
                      sm: "10px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                  }}
                >
                  Request Payment
                </Button> */}
              </>
            )}
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
}

export default DealInformation;
