import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  LinearProgress,
  Radio,
  RadioGroup,
} from "@mui/material";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import Link from "@mui/material/Link";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  getDealsDataById,
  Initiatetransaction,
  InitiatetransactionVerify,
  postNotify,
} from "../../../../../../../api/services/deals";

import DealInputField from "../../../../createDeal/DealInputField";

import { CustomLoadingButton } from "../../../../../../../components/buttons/SubmitLoaderButton";
import PaymentOtpVerification from "../PaymentOtpVerification";
import InitiatePaymentModel from "./InitiatePaymentModel";
import FileUploadContainer from "../../../../../../../components/shared/FileUploadContainer";

function AddFundsSeller() {
  const navigate = useNavigate();
  const { dealId } = useParams();
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => setIsChecked(!isChecked);
  const [openModel, setOpenModel] = useState(false);
  const [isFailed, setIsFailed] = useState<any>(false);
  const [open, setOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [uploadKey, setUploadKey] = useState("");

  const { data: dealData, isLoading }: any = useQuery(
    ["deal", dealId],
    getDealsDataById,
    {
      onError: () => {
        navigate("/deal-process/deals/all");
      },
    }
  );

  const handleFileChange = (fileKey: any) => {
    setUploadKey(fileKey);
  };

  const Limit =
    dealData?.data?.overview?.totalAmount - dealData?.data?.overview?.debit;

  const { mutate: initiateTransaction, isLoading: load2 } = useMutation(
    Initiatetransaction,
    {
      onSuccess: (res: any) => {
        toast.success("transaction initiated successfully");
        localStorage.setItem("transactionToken", res?.data?.data?.token);
        localStorage.setItem(
          "transactionmobile",
          res?.data?.data?.mobileNumber
        );
        setOpen(true);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const cancelTransaction = () => {
    setIsFailed(true);
    setOpen(false);
    setOpenModel(true);
  };

  const { mutate: VerifyTransaction, isLoading: load1 } = useMutation(
    InitiatetransactionVerify,
    {
      onSuccess: (res: any) => {
        toast.success("transaction successfully Completed");
        setOpenModel(true);
        setOpen(false);
      },
      onError: (error: any) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  const { mutate: notify, isLoading: userLoader } = useMutation(postNotify, {
    onSuccess: (res: any) => {
      toast.success("Request for Invoice sent successfully");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleNotifyUser = () => {
    notify({ dealCode: dealData?.data?.code, type: "Request Invoice" });
  };

  const submitFunds = () => {
    const data = {
      dealCode: dealId,
      transactionMode: getValues("paymentMode"),
      amount: getValues("amount")?.replace(/,/g, ""),
      remarks: getValues("remarks"),
      invoiceKey: uploadKey,
    };
    if (!data.dealCode) {
      toast.error("Deal code is required");
      return;
    } else if (!data.transactionMode) {
      toast.error("Payment mode is required");
      return;
    } else if (
      data.amount === null ||
      data.amount === undefined ||
      data.amount === ""
    ) {
      toast.error("Amount is required");
      return;
    } else if (!data.remarks) {
      toast.error("Remarks are required");
      return;
    } else {
      initiateTransaction(data);
    }
  };

  const sellerObject = dealData?.data?.dealUsers?.find(
    (user: any) => user?.userType === "Seller"
  );

  const buyerObject = dealData?.data?.dealUsers?.find(
    (user: any) => user?.userType === "Buyer"
  );

  const BuyerBankDetails = buyerObject?.banking;

  const sellerBankDetails = sellerObject?.banking;

  const ResendOTP = () => {
    submitFunds();
  };

  const handleVerify = (otp: any) => {
    const verifyData = {
      mobileNumber: localStorage.getItem("transactionmobile"),
      otp: otp,
      token: localStorage.getItem("transactionToken"),
      dealCode: dealId,
      transactionMode: getValues("paymentMode"),
      amount: getValues("amount")?.replace(/,/g, ""),
      remarks: getValues("remarks"),
      invoiceKey: uploadKey,
    };
    VerifyTransaction(verifyData);
  };

  const formatIndianNumber = (num: any) => {
    num = num?.toString();
    const lastThree = num?.substring(num.length - 3);
    const otherNumbers = num?.substring(0, num.length - 3);
    return (
      otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      (otherNumbers ? "," : "") +
      lastThree
    );
  };

  const formatIndianIntegers = (num: any) => {
    num = num?.toString();
    const [integerPart, decimalPart] = num?.split(".");

    const lastThree = integerPart.substring(integerPart.length - 3);
    const otherNumbers = integerPart.substring(0, integerPart.length - 3);

    const formattedInteger =
      otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
      (otherNumbers ? "," : "") +
      lastThree;

    return decimalPart
      ? `${formattedInteger}.${decimalPart}`
      : formattedInteger;
  };

  const {
    control,
    formState: { errors },
    getValues,
    setError,
    clearErrors,
    watch,
  } = useForm({});

  if (isLoading) {
    return (
      <>
        <LinearProgress />
      </>
    );
  }

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Card sx={{ width: "800px", height: "auto", py: 2 }}>
        <Box
          display="flex"
          alignItems="center"
          color="#7643EB"
          sx={{ cursor: "pointer", mb: 2, ml: 1 }}
        >
          <IconButton size="medium" onClick={() => navigate(-1)}>
            <ChevronLeftIcon fontSize="medium" sx={{ color: "#7643EB" }} />
          </IconButton>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{ color: "#7643EB", fontSize: "18px" }}
          >
            <Link
              underline="hover"
              color="#7643EB"
              href="/deal-process/deals/all"
              variant="subtitle2"
            >
              Deals
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}`}
              variant="subtitle2"
            >
              {dealId}
            </Link>
            <Link
              underline="hover"
              color="#7643EB"
              href={`/deal-process/deals/${dealId}/initiate-payment`}
              variant="subtitle2"
            >
              Initiate Payment
            </Link>
          </Breadcrumbs>
        </Box>

        <Box sx={{ px: 3 }}>
          <Typography variant="h3" my={2}>
            Initiate Payment
          </Typography>
          <Divider sx={{ width: "110%", ml: -3 }} />

          <Typography variant="h6" my={2} sx={{ fontWeight: 500 }}>
            Payer Details (Deal Escrow Account)
          </Typography>

          <Box
            my={2}
            sx={{
              backgroundColor: "#F3EFFE",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption">Account Number</Typography>
                <Typography variant="body1">
                  {dealData?.data?.bankingDetails?.accountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption">IFSC Code</Typography>
                <Typography variant="body1">
                  {dealData?.data?.bankingDetails?.ifscCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography variant="caption">Deal Amount</Typography>
                <Typography variant="body1">
                  {" ₹ " +
                    formatIndianIntegers(dealData?.data?.overview?.totalAmount)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <form>
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Mode of Payment</FormLabel>
              <Controller
                name="paymentMode"
                control={control}
                defaultValue="NE"
                render={({ field }) => (
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="NE"
                      control={<Radio />}
                      label="NEFT"
                    />
                    <FormControlLabel
                      value="RT"
                      control={<Radio />}
                      label="RTGS"
                    />
                    <FormControlLabel
                      value="PA"
                      control={<Radio />}
                      label="IMPS"
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>

            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <DealInputField
                  title="Deal Amount"
                  rightEndAdornment={true}
                  field={{
                    ...field,
                    onChange: (e: any) => {
                      let value = e.target.value.replace(/[^0.1-9]/g, "");

                      if (value.length > 15) {
                        value = value.slice(0, 15);
                      }

                      if (value === "") {
                        field.onChange(value);
                        clearErrors("amount");
                        return;
                      }

                      const numericValue = Number(value);

                      if (
                        // numericValue < 10000 ||
                        numericValue > dealData?.data?.overview?.balance
                      ) {
                        field.onChange(
                          formatIndianIntegers(
                            dealData?.data?.overview?.balance
                          )
                        );

                        setError("amount", {
                          type: "manual",
                          message:
                            "Value must be between 10,000 and 10,00,00,000",
                        });
                        return;
                      } else {
                        clearErrors("amount");
                      }

                      const formatIndianNumber = (num: any) => {
                        const lastThree = num.substring(num.length - 3);
                        const otherNumbers = num.substring(0, num.length - 3);
                        return (
                          otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
                          (otherNumbers ? "," : "") +
                          lastThree
                        );
                      };
                      const data = value?.split(".");

                      const formattedValue = formatIndianNumber(data[0]);

                      const finalData =
                        formattedValue +
                        (data[1] !== undefined
                          ? "." + data[1]?.slice(0, 2)
                          : "");
                      field.onChange(finalData);
                    },
                  }}
                  placeholder="Enter Deal Amount"
                  iD={"Deal Amount"}
                  errors={errors}
                />
              )}
            />
            <Typography
              sx={{
                color: "grey",
                textAlign: "center",
                paddingRight: "16vw",
              }}
            >
              Your remaining balance in bank account is ₹{" "}
              {formatIndianIntegers(
                Math.round(
                  (dealData?.data?.overview?.balance -
                    (watch("amount")?.replace(/,/g, "") || 0)) *
                    100
                ) / 100
              ) || 0}
            </Typography>

            <Controller
              name="remarks"
              control={control}
              render={({ field }) => (
                <DealInputField
                  type="remarks"
                  title="Remarks"
                  field={field}
                  placeholder="Enter Deal remarks"
                  iD={"remarks"}
                  errors={errors}
                  sx={{
                    width: {
                      xs: "256px",
                      sm: "550px",
                      lg: "550px",
                      xl: "550px",
                    },
                  }}
                />
              )}
            />
          </form>

          <Typography variant="h6" my={2} sx={{ fontWeight: 500 }}>
            Beneficiary Details (Seller)
          </Typography>

          <Box
            my={2}
            sx={{
              backgroundColor: "#F3EFFE",
              padding: "10px 20px",
              borderRadius: "5px",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  Beneficiary Account Number
                </Typography>
                <Typography variant="body1">
                  {sellerBankDetails?.accountNumber}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  IFSC Code
                </Typography>
                <Typography variant="subtitle2">
                  {sellerBankDetails?.ifscCode}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  Beneficiary Name
                </Typography>
                <Typography variant="body1">
                  {sellerBankDetails?.beneficiaryName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography variant="body2" sx={{ color: "grey" }}>
                  Limit
                </Typography>
                <Typography variant="body1">
                  {" ₹ " + formatIndianNumber(Limit)}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box my={"10px"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" my={2} sx={{ fontWeight: 500 }}>
                Supporting Invoice
              </Typography>
              <CustomLoadingButton
                onClick={handleNotifyUser}
                title={` Notify Seller for Invoice`}
                submitLoader={userLoader}
                width={"200px"}
              ></CustomLoadingButton>
            </Box>

            <FileUploadContainer
              onFileChange={handleFileChange}
              typeData="BANKING"
              data="PAYMENT_INVOICE"
              uploaded={false}
              url=""
              filetype="pdf"
              actions={null}
              status={false}
              isSupporting={"false"}
              isDeleted={setIsDeleted}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <Checkbox
              checked={isChecked}
              onChange={handleCheckboxChange}
              color="primary"
              sx={{
                "& .MuiSvgIcon-root": {},
                "& .MuiCheckbox-root": {
                  color: isChecked ? "primary.main" : "transparent",
                },
                mb: "5px",
              }}
            />
            <Typography
              variant="body1"
              gutterBottom
              align="left"
              onClick={handleCheckboxChange}
              sx={{ cursor: "pointer" }}
            >
              I Accept the terms and conditions regarding the usage of TruuPe
              Platform
            </Typography>
          </Box>

          <Box my={"2vh"}>
            <CustomLoadingButton
              onClick={() => {
                submitFunds();
              }}
              title={"Submit"}
              submitLoader={load2 && !open}
              width={"200x"}
              isDisabled={!isChecked || !isDeleted}
            ></CustomLoadingButton>
          </Box>
        </Box>

        <PaymentOtpVerification
          ResendOTP={ResendOTP}
          Text={"mobile number."}
          open={open}
          handleVerify={handleVerify}
          mobileNumber={localStorage.getItem("transactionmobile")}
          isSignup={false}
          isRegister={false}
          BuyerBankDetails={BuyerBankDetails}
          setOpen={setOpen}
          isLoad={load1 || load2}
          cancelTransaction={cancelTransaction}
        />
        <InitiatePaymentModel
          openModel={openModel}
          setOpenModel={setOpenModel}
          amount={getValues("amount")}
          isFailed={isFailed}
        />
      </Card>
    </Box>
  );
}

export default AddFundsSeller;
