import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { Icons } from "../../../assets";
import { stripHTML } from "../../../utils/dealFunctions";
import Status from "../../dealProcess/deals/userDeal/dealStatus/DealStatus";
import DrawerButtonAction from "../components/DrawerButtonAction";
import SignatoryDocumentsForm from "./SignatoryDocumentsForm";

interface SignatureAuthorityProps {
  data: {
    name: string;
    designation: string;
    emailAddress: string;
    mobileNumber: string;
    aadharNumber: string;
    isAadharMismatch: boolean;
    panNumber: string;
    isPanMisMatch: boolean;
    documents: any;
    areDocumentsUploaded: boolean;
    status: string;
    reason: string;
  };
  currentDirectorCount: number;
  entityStatus: any;
}

const DocumentsMissingAlert = styled(Typography)({
  opacity: "85%",
  color: "#800000",
  margin: "8px 0",
  fontStyle: "italic",
});

const SignatureAuthority: React.FC<SignatureAuthorityProps> = ({
  data,
  currentDirectorCount,
  entityStatus,
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const maskAadharNumber = (aadharNumber: string) =>
    aadharNumber?.slice(0, -4).replace(/\d/g, "*") + aadharNumber?.slice(-4);

  const maskPanNumber = (panNumber: string) =>
    panNumber?.slice(0, -4).replace(/[A-Z0-9]/g, "X") + panNumber?.slice(-4);

  const areDocumentsUploaded = data?.areDocumentsUploaded;
  const directorStatus = data?.status;

  return (
    <Box sx={{ marginLeft: "20px", marginBottom: "50px" }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        width={"100%"}
        justifyContent={"space-between"}
        marginTop={"10px"}
      >
        <Typography variant="h6" marginBottom={"15px"}>
          Signature Authority {currentDirectorCount}
        </Typography>

        <DrawerButtonAction
          subtype={`Add Signatory ${currentDirectorCount} Documents`}
          title={`Add Signatory ${currentDirectorCount} Documents`}
          contentStyles={{
            paddingTop: "0px",
          }}
          anchor={({ open }) => (
            <>
              {!areDocumentsUploaded ? (
                <Stack direction={"column"}>
                  <Button
                    variant="outlined"
                    onClick={open}
                    sx={{
                      fontSize: "10px",
                      height: "30px",
                    }}
                  >
                    Add Signatory Documents
                  </Button>
                  <Stack direction={"row"} alignItems={"center"}>
                    <img
                      height={"20px"}
                      src={Icons.alert}
                      alt="loading...."
                      style={{ marginRight: "5px" }}
                    />
                    <DocumentsMissingAlert variant="caption">
                      Please add Signatory Documents
                    </DocumentsMissingAlert>
                  </Stack>
                </Stack>
              ) : (
                !["Kyc Submitted", "Rejected"].includes(entityStatus) &&
                (directorStatus === "In Progress" ? (
                  <Status variant="PENDING" />
                ) : directorStatus === "Verified" ? (
                  <Status variant="VERIFIED" />
                ) : directorStatus === "Rejected" ? (
                  <Status
                    variant="REJECTED"
                    showTooltip={true}
                    tooltipText={stripHTML(data.reason) || "NA"}
                  />
                ) : null)
              )}
            </>
          )}
          content={({ close }) => (
            <>
              <SignatoryDocumentsForm data={data} close={close} />
            </>
          )}
        />
      </Stack>

      <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            sx={{ justifyContent: isTabletOrMobile ? "center" : "left" }}
          >
            {/* SignatureAuthority Information */}
            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">Name</Typography>
              <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
                {data.name}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">Designation</Typography>
              <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
                {data.designation || "Not Available"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">Email ID</Typography>
              <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
                {data.emailAddress}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">Phone Number</Typography>
              <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
                {data.mobileNumber}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">Aadhar Number</Typography>
              <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
                {maskAadharNumber(data.aadharNumber)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">Aadhar Status</Typography>
              <Typography
                variant="subtitle2"
                color={data.isAadharMismatch ? "red" : "green"}
              >
                {data.isAadharMismatch ? "Mismatch" : "Verified"}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={2.4}>
              <Typography variant="caption">PAN Number</Typography>
              <Typography variant="subtitle2" sx={{ wordBreak: "break-word" }}>
                {maskPanNumber(data.panNumber)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SignatureAuthority;
